import { CognovisRestInvoiceService, IInvoice, IInvoiceItem, IntranetUom } from "../../../sources/openapi";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { container } from "tsyringe";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";

export default class DiscountsModal extends CognovisBasicModal {

    idPrefix = "dm";
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    invoiceData:IInvoice;
    invoiceItems:IInvoiceItem[] = [];
    callbackAction:() => void;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Send E-Mail`), 1040,340);
        return mainLayout;
    }

    init():void {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
    }

    openModal(invoiceData:IInvoice, callbackAction?:() => void):void {
        this.invoiceData = invoiceData;
        if(callbackAction) {
            this.callbackAction = callbackAction;
        }
        CognovisRestInvoiceService.getInvoiceItem({invoiceId:this.invoiceData.invoice.id})
        .then(invoiceItems => {
            this.invoiceItems = invoiceItems;
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
            const modalContent = this.getContent();
            const modalTitle = i18nHelper.getTranslation("Import_Discounts")
            const actionButtons = this.getActionButtons();
            this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
        });
    }

    closeModal():void {
        this.hide();
    }

    getContent():webix.ui.layoutConfig {
        const layout = {
            view:"scrollview",
            body:{
                padding:30,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}DiscountLinesContainer`,
                        rows:[
                            this.getSingleDiscountLine(0),
                        ]
                    },
                    this.getPlusButton()
                    
                ]
            }
        };
        return layout
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Cancel_and_go_back`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Import_discounts`)}`,
                            align: "right",
                            click:() => {
                               this.importDiscounts(this.invoiceData);
                            }
                        },    
                    ] 
                };
        return buttons;
    }

    getSingleDiscountLine(index:number):webix.ui.layoutConfig {
        const singleDiscountLayout = {
            view:"form",
            borderless:true,
            discountLine:true,
            id:`${this.idPrefix}SingleDiscountLineForm-${index}`,
            type:"percentage",
            paddingY:5,
            paddingX:0,
            cols:[
                {
                    view:"text",
                    name:"name",
                    gravity:0.6
                },
                {
                    view:"text",
                    name:"value",
                    id:`${this.idPrefix}SingleDiscountLineFormValue-${index}`,
                    gravity:0.2,
                    on: {
                        onKeyPress:function(charCode:number, e:Event){
                            if(charCode === 188) {
                                e.preventDefault();
                                this.setValue(`${this.getValue()}.`)
                            }
                        }
                    }
                },
                {
                    view:"layout",
                    gravity:0.2,
                    paddingX:0,
                    cols:[
                        {
                            view:"button",
                            status:"active",
                            borderless:true,
                            padding:0,
                            width:50,
                            align:"right",
                            height:40,
                            label:`<div id='${this.idPrefix}SingleDiscountButtonPercentage-${index}-node' class='discount-box active'><span class='fas fa-percentage'></span></div>`,
                            click:() => {
                                this.setDiscountType("percentage",index);
                            }
                        },
                        {
                            view:"button",
                            borderless:true,
                            status:"not-active",
                            padding:0,
                            width:50,
                            align:"right",
                            height:40,
                            label:`<div id='${this.idPrefix}SingleDiscountButtonAmount-${index}-node' class='discount-box'><span class='fas fa-euro-sign'></span></div>`,
                            click:() => {
                                this.setDiscountType("amount",index);
                            }
                        }
                    ]
                }

            ]
        };
        return singleDiscountLayout;
    }

    getPlusButton():webix.ui.layoutConfig {
        const buttonLayout = {
            view:"button",
            value:`<span class='fas fa-plus-circle'></span>`,
            css:"discounts-add-button",
            click:() => {
                this.addDiscountLine()
            }
        }
        return buttonLayout;
    }

    getAllDiscountLines():webix.ui.formConfig[] {
        const container = this.$$(`${this.idPrefix}DiscountLinesContainer`) as webix.ui.layout;
        const discountLines = container.queryView({view:"form"}, "all");
        const filtered = discountLines.filter(discount => discount.config.discountLine);
        return filtered;
    }

    getTotalNumberOfDiscountLines():number {
        const discountLines = this.getAllDiscountLines();
        return discountLines.length;
    }

    addDiscountLine():void {
        const container = this.$$(`${this.idPrefix}DiscountLinesContainer`) as webix.ui.layout;
        const index = (this.getTotalNumberOfDiscountLines());
        container.addView(this.getSingleDiscountLine(index));
    }

    setDiscountType(type:string, index:number):void {
        const container = webix.$$(`${this.idPrefix}SingleDiscountLineForm-${index}`);
        container.config["type"] = "";
        const percentageButtonNode = document.getElementById(`${this.idPrefix}SingleDiscountButtonPercentage-${index}-node`) as HTMLElement;
        const amountButtonNode = document.getElementById(`${this.idPrefix}SingleDiscountButtonAmount-${index}-node`) as HTMLElement;
        webix.html.removeCss(percentageButtonNode, "active");
        webix.html.removeCss(amountButtonNode, "active");
        switch(type) {
            case "percentage":
                container.config["type"] = "percentage";
                webix.html.addCss(percentageButtonNode, "active");
                webix.html.removeCss(amountButtonNode, "active");
                break;
            case "amount":
                container.config["type"] = "amount";
                webix.html.addCss(amountButtonNode, "active");
                webix.html.removeCss(percentageButtonNode, "active");
                break;
        }
    }   

    importDiscounts(invoiceData:IInvoice):void {
        const discounts = this.getAllDiscountLines();
        // Validate fields
        let totalValidDidscountLines = 0;
        discounts.map((discount:webix.ui.form) => {
            const values = discount.getValues();
            if(values.value !== "" && values.name !== "") {
                totalValidDidscountLines ++;
            }
        });
        if(totalValidDidscountLines === this.getTotalNumberOfDiscountLines()) {
            const currentInvoiceAmount = this.invoiceItems.reduce((a, b) => {
                const netAmount = Number(b.price_per_unit) * Number(Math.abs(b.item_units));
                return a + netAmount
            },0);
            this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
            discounts.map((discount:webix.ui.form, index:number) => {
                const type = discount.config["type"];
                const values = discount.getValues();
                let calculatedDiscount;
                if(type === "percentage") {
                    const numericValue = parseFloat(values.value);
                    calculatedDiscount = (currentInvoiceAmount*(numericValue/100));
                }
                if(type === "amount") {
                    calculatedDiscount = parseFloat(values.value);
                }
                let materialNr = "surcharge";
                if(values.value < 0) {
                    materialNr = "discount";
                }
                const requestBody = {
                    item_name:values.name,
                    item_units:1,
                    price_per_unit:calculatedDiscount,
                    material_nr:materialNr,
                    item_uom_id:IntranetUom.UNIT.toString()
                };
                CognovisRestInvoiceService.postInvoiceItem({
                    invoiceId:invoiceData.invoice.id,
                    requestBody: requestBody
                })  
                .then(() => {
                    if(this.getTotalNumberOfDiscountLines() === (index+1)) {
                        this.cognovisPleaseWaitWindow.hide();
                        this.closeModal();
                        this.callbackAction();
                    }
                });
            });
        } else {
            const requiredFieldsTranslated = i18nHelper.getTranslation("Missing_required_fields");
            const pleaseProvideValues = i18nHelper.getTranslation("Please_provide_values_for_discounts");
            webix.alert({
                title:requiredFieldsTranslated,
                text:pleaseProvideValues,
                modal:true,
                type:"alert-error"
            });
        }
    }
    

}