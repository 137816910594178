import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisRestTimesheetService, ITimesheetTask } from "../../../sources/openapi";

export default class TimesheetTaskModal extends CognovisBasicModal {

    idPrefix = "ttm";
    projectId:number;
    timesheetTask:ITimesheetTask;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    webixHelpers:WebixHelpers;
    afterSaveAction:() => void;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 1200, 640);
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(projectId:number,timesheetTask?:ITimesheetTask, afterSaveAction?:() => void):void {
        this.projectId = projectId;
        if(timesheetTask) {
            this.timesheetTask = timesheetTask;
        } else {
            this.timesheetTask = undefined;
        }
        if(afterSaveAction) {
            this.afterSaveAction = afterSaveAction;
        }
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const modalContent = this.getContent();
        const modalTitle = i18nHelper.getTranslation(`Add time tracking task`)
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);   
        if(!timesheetTask) {
            this.clearFormFields();
        }
    }

    getContent():webix.ui.layoutConfig {
        return {
            view:"layout",
            borderless:true,
            rows:[
                {
                    view:"form",
                    localId:`${this.idPrefix}TimesheetTaskModalForm`,
                    elements:[
                        {
                            view:"text",
                            name:"taskName",
                            value:this.timesheetTask?.task.name,
                            label:i18nHelper.getTranslation("Time_tracking_task"),
                            labelWidth:180,
                            width:560,
                            on:{
                                onAfterRender:function() {
                                    webix.delay(() => {
                                        this.focus();
                                    },500); 
                                }
                            }
                        },
                        {
                            view: "combo",
                            name: "taskTypeId",
                            value:this.timesheetTask?.task_type?.id,
                            label:i18nHelper.getTranslation("task_type"),
                            placeholder:i18nHelper.getTranslation("task_type"),
                            labelWidth:180,
                            width:560,
                            suggest: {
                                id:`${this.idPrefix}AddTimesheetTaskTypeSuggest`,
                                body: {
                                    url: () => CognovisCategory.getCategory("Intranet Gantt Task Type"),
                                }
                            },
                        },
                        // {
                        //     view: "combo",
                        //     name: "taskStatusId",
                        //     value:(this.timesheetTask?.task_status?.id ? this.timesheetTask?.task_status?.id : 9600),
                        //     label:i18nHelper.getTranslation("task_status"),
                        //     placeholder:i18nHelper.getTranslation("task_status"),
                        //     labelWidth:180,
                        //     width:560,
                        //     suggest: {
                        //         id:`${this.idPrefix}AddTimesheetTaskStatusSuggest`,
                        //         body: {
                        //             url: () => CognovisCategory.getCategory("Intranet Gantt Task Status"),
                        //         }
                        //     },
                        // },
                        {
                            view:"text",
                            name:"plannedUnits",
                            value:this.timesheetTask?.planned_units,
                            label:i18nHelper.getTranslation("Hours_planned"),
                            labelWidth:180,
                            width:560,
                        }, 
                        {
                            view:"checkbox",
                            value:((this.timesheetTask?.billable_units != null) ? 1 : 0),
                            name:"isBillable",
                            labelWidth:180,
                            width:560,
                            label:i18nHelper.getTranslation("Billable")
                        }   
                    ]
                },
            ]
        }  as webix.ui.layoutConfig
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        {
                            view:"button",
                            value:(this.timesheetTask?.task) ? i18nHelper.getTranslation("Save") : i18nHelper.getTranslation("Create_new_time_tracking_task"),
                            click:() => this.submitForm()
                        }
                    ] 
                };
        return buttons;
    }


    closeModal():void {
        this.clearFormFields();
        this.hide();
    }

    submitForm():void {
        if(this.timesheetTask) {
            this.saveExistingTimesheetTask();
        } else {
            this.createNewTimeTrackingTask();
        }
    }

    createNewTimeTrackingTask():void {
        const form = this.$$(`${this.idPrefix}TimesheetTaskModalForm`) as webix.ui.form;
        const values = form.getValues();
        let billableUnits = null;
        if(values.isBillable) {
            billableUnits = values.plannedUnits;
        }
        CognovisRestTimesheetService.postTimesheetTask({
            projectId:this.projectId,
            requestBody: {
                task_name: values.taskName,
                planned_units: values.plannedUnits,
                billable_units:billableUnits,
                task_type_id: values.taskTypeId,
                task_status_id: values.taskStatusId
            }
        })
        .then(() => {
            if(this.afterSaveAction) {
                this.afterSaveAction();
            }
            this.closeModal();
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            if(this.afterSaveAction) {
                this.afterSaveAction();
            }
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal",
            });
        });
    }

    saveExistingTimesheetTask():void {
        const form = this.$$(`${this.idPrefix}TimesheetTaskModalForm`) as webix.ui.form;
        const values = form.getValues();
        let billableUnits = null;
        if(values.isBillable) {
            billableUnits = values.plannedUnits;
        }
        CognovisRestTimesheetService.putTimesheetTask({
            taskId:this.timesheetTask.task.id,
            requestBody:{
                task_name: values.taskName,
                planned_units: values.plannedUnits,
                billable_units:billableUnits,
                task_type_id: values.taskTypeId,
                task_status_id: values.taskStatusId
            }
        })
        .then(() => {
            if(this.afterSaveAction) {
                this.afterSaveAction();
            }
            this.closeModal();
        })
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            if(this.afterSaveAction) {
                this.afterSaveAction();
            }
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal",
            });
        });
    }

    clearFormFields():void {
        const form = this.$$(`${this.idPrefix}TimesheetTaskModalForm`) as webix.ui.form;
        form.clear();
    }

}