import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import { CognovisRestCompanyService, ICompanyContact, IUser} from "../../../sources/openapi";
import CognovisBasicModal from "../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { container } from "tsyringe";

export default class UserDataModal extends CognovisBasicModal {

    windowId = "userCreationModal";
    companyId:number;
    elementsPrefix = "ucm";
    userData:ICompanyContact;
    afterSubmitAction:(newCompanyId:ICompanyContact) => void;
    autoFocusOnFieldId:string;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

    config():webix.ui.windowConfig {
        // Here is a small trick.
        // We need to pass whitespace (" ") here or any other string as modal title
        // Otherwise we won't be able to later change it
        const mainLayout = super.getMainLayout(" ");
        this.autoFocusOnFieldId = `${this.elementsPrefix}firstNames`
        return mainLayout;
    }

    init():void {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);  
    }

    openModal(visibleFieldsNames:string[], companyId:number, userData?:ICompanyContact, afterSubmitAction?:(companyContact:ICompanyContact) => void):void {
        if(userData) {
            this.userData = userData;
        }
        if(afterSubmitAction) {
            this.afterSubmitAction = afterSubmitAction;
        }
        this.companyId = companyId;
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const actionButtons = this.getActionButtons();
        const modalContent = this.getContent();
        let modalTitle = i18nHelper.getTranslation(`Create New Customer`);
        if(this.userData) {
            modalTitle = this.userData?.contact?.name;
        }
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
        this.toggleInputs(visibleFieldsNames); 
        webix.delay(() => {
            const autofocusField = webix.$$(this.autoFocusOnFieldId) as webix.ui.text;
            if(autofocusField) {
                autofocusField.focus();
            }
        },1000);
    }

    closeModal():void {
        this.hide();
    }

    toggleInputs(inputNames?:string[]):void {
        if(inputNames.length > 0) {
            const form = webix.$$(`${this.elementsPrefix}Form`) as webix.ui.form;
            if(form["_collection"]) {
                const formFields = form["_collection"];
                // First we hide all input fields
                formFields.map(field => {
                    const element = webix.$$(field.id);
                    element.hide();
                });
                // Now we decide which ones should be visible
                formFields.map(field => {
                    if(!inputNames || inputNames.indexOf(field.name) > -1) {
                        const element = webix.$$(field.id);
                        element.show();
                    }
                });
            }
        }
    }

    getContent():webix.ui.layoutConfig {
        const modal = {
            view:"scrollview",
            minHeight:450,
            minWidth:800,
            body:{
                view:"form",
                id:`${this.elementsPrefix}Form`,
                elements:[
                    {
                        view:"text",
                        id:`${this.elementsPrefix}FirstNames`,
                        value:this.userData?.first_names,
                        name:"first_names",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("First_names"),
                        placeholder:i18nHelper.getTranslation("First_names")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}LastName`,
                        value:this.userData?.last_name,
                        name:"last_name",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Last_name"),
                        placeholder:i18nHelper.getTranslation("Last_name")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}Email`,
                        value:this.userData?.email,
                        name:"email",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Email"),
                        placeholder:i18nHelper.getTranslation("Email")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}SystemName`,
                        name:"system_name",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("System_name"),
                        placeholder:i18nHelper.getTranslation("System_name")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}Password`,
                        name:"password",
                        type:"password",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Password"),
                        placeholder:i18nHelper.getTranslation("Password")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}PasswordConfirm`,
                        name:"password_confirm",
                        type:"password",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Password_confirm"),
                        placeholder:i18nHelper.getTranslation("Password_confirm")
                    },
                    {
                        view: "combo",
                        id:`${this.elementsPrefix}Salutation`,
                        value:this.userData?.salutation,
                        name: "salutation",
                        label:i18nHelper.getTranslation("Salutation"),
                        placeholder:i18nHelper.getTranslation("Salutation"),
                        labelWidth:200,
                        labelPosition:"left",
                        suggest: {
                            body: {
                                url: () => CognovisCategory.getCategory("Intranet Salutation"),
                            }
                        },
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}WorkPhone`,
                        value:this.userData?.work_phone,
                        name:"work_phone",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Work_phone"),
                        placeholder:i18nHelper.getTranslation("Work_phone")
                    },
                    {
                        view:"text",
                        id:`${this.elementsPrefix}WorkMobile`,
                        value:this.userData?.cell_phone,
                        name:"cell_phone",
                        labelWidth:200,
                        label:i18nHelper.getTranslation("Work_mobile"),
                        placeholder:i18nHelper.getTranslation("Work_mobile")
                    }
                ],
            }
        };
        return modal;
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Cancel`)}`, 
                            align: "left",
                            click:() => {
                                this.hide();
                            }
                        },    
                        { 
                            view: "button", 
                            id:`${this.elementsPrefix}SubmitNewCompanyContactButton`,
                            value: `${i18nHelper.getTranslation(`save`)}`,
                            align: "right",
                            click:() => {
                                this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                                const form = webix.$$(`${this.elementsPrefix}Form`) as webix.ui.form;
                                if (form.validate()) {
                                    const values = form.getValues();
                                    if(this.userData?.contact) {
                                        CognovisRestCompanyService.putCompanyContact({
                                            contactId:this.userData?.contact.id,
                                            requestBody:{
                                                first_names:values.first_names,
                                                last_name:values.last_name, 
                                                email:values.email,
                                                work_phone:values.work_phone,
                                                cell_phone:values.cell_phone
                                            } 
                                        })
                                        .then((companyContact:ICompanyContact) => {
                                            this.cognovisPleaseWaitWindow.hide();
                                            this.closeModal();
                                            if(this.afterSubmitAction) {
                                                this.afterSubmitAction(companyContact);
                                            }
                                        });
                                    } else {
                                        CognovisRestCompanyService.postCompanyContact({
                                            companyId:this.companyId,
                                            requestBody:{
                                                first_names:values.first_names,
                                                last_name:values.last_name, 
                                                email:values.email,
                                                password:values.password,
                                                password_confirm:values.password_confirm,
                                                locale:"",
                                                salutation_id:values.salutation,
                                                work_phone:values.work_phone,
                                                cell_phone:values.cell_phone,
                                                position:""
                                            } as any
                                        })
                                        .then((companyContact:ICompanyContact) => {
                                            this.cognovisPleaseWaitWindow.hide();
                                            this.closeModal();
                                            this.afterSubmitAction(companyContact);
                                        });
                                    }
                                }
                            }
                        },    
                    ] 
                };
        return buttons;
    }

}