import { config } from "../../sources/config";
import { JetView } from "webix-jet";
import { i18nHelper } from "./i18n-helper/i18n-helper";
import CognovisProfile from "./cognovis-profile/cognovis-profile";
import { UserProfile } from "./cognovis-profile/profiles-types";

export default class CognovisBasicPortlet extends JetView {

    idPrefix = "cbp";
    title = "";
    components:webix.ui.baselayoutConfig[] = [];
    portletId:number;

    config():webix.ui.layoutConfig {
        return this.getCoreLayout();
    }

    getCoreLayout():webix.ui.layoutConfig {
        return {
            view:"layout",
            height:0,
            gravity:1,
            rows:[
                this.getHeader({}), 
                {
                    view:"layout",
                    id:`${this.idPrefix}MainContainer`,
                    height:0,
                    gravity:1,
                    minHeight:300,
                    css:"cog-box-content",
                    rows:[
                    ]
                }
            ]
        } as unknown as webix.ui.layoutConfig
    }

    init():void {
        this.showLoader();
        this.addIcons();
    }

    getHeader<T>(object:T):webix.ui.layoutConfig {
        return {
            view:"layout",
            height:44,
            css: "cog-box-header", 
            cols: [
                { 
                    view: "template", 
                    id:`${this.idPrefix}Header`,
                    type: "header",
                    css: "cog-box-header",  
                    template: this.title, 
                    borderless: true 
                }
            ]
        };
    }

    getAdminHeaderIcons(portletId:number):webix.ui.buttonConfig {
        if(portletId && CognovisProfile.isUserInGivenGroup(UserProfile.PO_ADMIN)) {
            return {
                view: "button", 
                type: "icon", 
                icon: "far fa-gear", 
                align: "right", 
                css:"action-icon-grey",
                width: 28, 
                borderless:true,
                click:(element, event) => {
                    const url = `${config.webixUrl}/portlet?portlet_id=${portletId}`;
                    window.open(url,'_blank');
                }
            } as unknown as webix.ui.buttonConfig
        } else {
            return {
                view:"spacer",
                width:1
            }
        }
    }

    setHeaderTitle(title:string):void {
        const rootView = this.getRoot() as webix.ui.layout;
        if(rootView) {
            const headerCmp = rootView.queryView({type:"header"});
            if(headerCmp) {
                headerCmp.setHTML(title);
            }
        }
    }

    addIcons():void {
        const icons = this.getAdminHeaderIcons(this.portletId);
        const rootView = this.getRoot() as webix.ui.layout;
        if(rootView) {
            const headerCmp:webix.ui.baseview = rootView.queryView({type:"header"});
            const parentHeaderCmp = headerCmp.getParentView();
            parentHeaderCmp.addView(icons);
        }
    }

    insertComponents():void {
        const container = this.$$(`${this.idPrefix}MainContainer`) as webix.ui.layout;
        if(container) {
            container.config.minHeight = 0;
            if(container) {
                const rows = [
                    ...this.components
                ];
                const layout = {
                    view:"layout",
                    localId:`${this.idPrefix}Dynfields`,
                    rows:rows
                }
                container.addView(layout);
            }
            this.hideLoader();
        }
    }

    showLoader():void {
        const portlet = this.$$(`${this.idPrefix}MainContainer`) as webix.ui.layout;
        webix.extend(portlet,webix.ProgressBar);
        (portlet as any).showProgress(i18nHelper.getTranslation("Please_wait"));
    }

    hideLoader():void {
        const portlet = this.$$(`${this.idPrefix}MainContainer`) as webix.ui.layout;
        webix.extend(portlet,webix.ProgressBar);
        (portlet as any).hideProgress();
    }

    showError(error:string):void {
        this.hideLoader();
        const portlet = this.$$(`${this.idPrefix}MainContainer`) as webix.ui.layout;
        webix.extend(portlet,webix.OverlayBox);
        if(error && error.length > 0) {
            (portlet as webix.ui.layout & {showOverlay:(message:string) => void}).showOverlay(`<div style='margin-top:85px'>${error}</div>`);
        } else {
            (portlet as webix.ui.layout & {showOverlay:(message:string) => void}).showOverlay(`<div style='margin-top:85px'>${i18nHelper.getTranslation("Data could not be loaded")}</div>`);
        }
    }

}