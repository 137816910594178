import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import { JetView } from "webix-jet";
import {config} from "../../config";
import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { container } from "tsyringe";
import GeneralChatBackend  from "./general-chat-backend";
import * as chat from "@xbs/chat";
import { UserStateManager } from "../../services/user-state-manager";
import { WebixCallback } from "@xbs/webix-pro";

// General chat
export default class GeneralChat extends JetView {


    idPrefix = "cc";
    chatUrl = config.chatUrl;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    chat:webix.ui.chat;
    token:string;
    
    config():webix.ui.layoutConfig {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        // First we must login to chat
        return {
            view:"layout",
            localId:`${this.idPrefix}ChatContainer`,
            rows:[]
        } as unknown as webix.ui.layoutConfig
    }

    getChatUI(token:string):webix.ui.chatConfig {
        this.token = token;
        return  {
            view:"chat",
            id:`${this.idPrefix}Chat`,
            token: token,
            url:`${this.chatUrl}/api/v1`,
            override: new Map([[chat.services.Backend, GeneralChatBackend]]),
            // on: {
            //     onInit:app => {
            //         this.hideNotNeededElements();
            //     }
            // }

        } as unknown as webix.ui.chatConfig
    }

    init(view):void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        this.loginToChat(fetchedToken => {
            const chatUI = this.getChatUI(fetchedToken);
            const container = this.$$(`${this.idPrefix}ChatContainer`) as webix.ui.layout;
            container.define("rows", [chatUI]);
            container.reconstruct();
            this.chat = (webix.$$(`${this.idPrefix}Chat`) as webix.ui.chat);
            this.cognovisPleaseWaitWindow.hide();
        });
    }
    
    getChatStatus():void {
        webix.ajax().get(`${this.chatUrl}/api/status`)
        .then(status => {
            return status.json()
        })
        .then(stat => {
            console.log(stat.Hub.Channels.chats.Subscribed)
        });
    }

    loginToChat(callback:WebixCallback):void {
        const currentUserId = UserStateManager.getCurrentlyLoggedUser().id;
        webix.ajax().headers({
            "Content-type":"application/json"
        })
        .post(
            `${this.chatUrl}/cog-login`,
            {
                id:currentUserId,
                email:`${UserStateManager.currentlyLoggedUser.userData.user.name}@`,
                avatar:UserStateManager.currentlyLoggedUser.userData.portrait_url,
                name:UserStateManager.currentlyLoggedUser.userData.user.name
            },
            (res) => {
                callback(res);
            }
        )
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text:i18nHelper.getTranslation("Could not connect co chat"),
                minWidth:500,
                type: "alert-error"
            });
        });
    }

}