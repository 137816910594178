import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import {
    CognovisRestInvoiceService,
    IInvoice,
    IntranetCostType,
} from "../../../sources/openapi/index";
import BaseView from "../baseview";
import { FileHelper } from "../../../sources/modules/file-helpers/file-helpers";

export default class Invoices extends BaseView {

    idPrefix:"nefo";
    

    config():webix.ui.layoutConfig {
        return this.getLayout()
    }

    init():void {
        this.loadDataIntoTable();
    }

    getLayout():webix.ui.layoutConfig {
        const layout = {
            view:"layout",
            padding:13,
            css:"cog-content",
            rows:[
                this.getInvoicesTable()
            ]
        }
        return layout;
    }

    getInvoicesTable():webix.ui.datatableConfig {
        return {
            view:"datatable",
            localId:`${this.idPrefix}InvoicesDatatable`,
            columns:this.getInvoicesTableColumns(),
            on:{
                onItemClick:(row) => {
                    const invoicesTable = this.$$(`${this.idPrefix}InvoicesDatatable`) as webix.ui.datatable;
                    const item = invoicesTable.getItem(row);
                    const invoiceId = item.invoice.id;
                    switch(row.column) {
                        case "cost_name":
                            FileHelper.downloadFinanceDocument(invoiceId);
                            break;
                        default:
                            break;
                    }
                }
            }
        } as unknown as webix.ui.datatableConfig
    }

    getInvoicesTableColumns():webix.ui.datatableConfig {
        return [
            {
                id:"cost_type",
                map:"#cost_type.name#",
                header: [i18nHelper.getTranslation("Type"), { content:"selectFilter"}], 
                fillspace:true
            },  
            {
                id:"cost_name",
                map:"#invoice.name#",
                header: [i18nHelper.getTranslation("Name"), { content:"textFilter"}], 
                sort:"text",
                fillspace:true,
                template:function(obj:IInvoice) {
                    return `<span class='webix-datatable-link'>${obj.invoice.name}</span>`
                }
            },
            {
                id:"amount",
                map:"#grand_total#",
                css:"price-display",
                sort:PriceHelper.sortDatatableByFormattedGrandTotal,
                width:140,
                header: [{text:i18nHelper.getTranslation("Price")},{ content:"numberFilter"}],
                template:function(obj) {
                    return PriceHelper.formatPrice(obj.grand_total);
                }
            },
            { 
                id: "formatted_creation_date", 
                map:"(date)#formatted_creation_date#",
                header: [{text:i18nHelper.getTranslation("Created")}, {content:"dateRangeFilter"}],
                width:140,
                sort:"date",
                template:function(obj) {
                    if(obj.creation_date) {
                        const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
                        const formattedDate = myFormat(new Date(obj.creation_date));
                        return formattedDate
                    } else {
                        return ""
                    }
                }
            },
            { 
                id: "formatted_effective_date", 
                map:"(date)#formatted_effective_date#",
                header: [{text:i18nHelper.getTranslation("Effective_date")}, {content:"dateRangeFilter"}],
                width:140,
                sort:"date",
                template:function(obj) {
                    if(obj.effective_date) {
                        const myFormat = webix.Date.dateToStr("%d.%m.%Y", false);
                        const formattedDate = myFormat(new Date(obj.effective_date));
                        return formattedDate
                    } else {
                        return ""
                    }
                }
            },
            {
                id:"cost_status",
                map:"#cost_status.name#",
                header: [i18nHelper.getTranslation("Status"), { content:"selectFilter"}], 
                fillspace:true
            }
        ] as unknown as webix.ui.datatableConfig
    }

    getInvoices():Promise<IInvoice[]> {
        return CognovisRestInvoiceService.getInvoice({})
        .then(invoices => {
            invoices.map((invoice:IInvoice & {formatted_creation_date:string, formatted_effective_date:string}) => {
                const creationDateObj = new Date(invoice.creation_date)
                const creationDateAsString = `${creationDateObj.getFullYear()}-${(creationDateObj.getMonth()+1)}-${creationDateObj.getDate()}`;
                invoice.formatted_creation_date = creationDateAsString;
                const effectiveDateObj = new Date(invoice.effective_date)
                const effectiveDateAsString = `${effectiveDateObj.getFullYear()}-${(effectiveDateObj.getMonth()+1)}-${effectiveDateObj.getDate()}`;
                invoice.formatted_effective_date = effectiveDateAsString;
            });
            return invoices
        });
    }

    loadDataIntoTable():void {
        const invoicesTable = this.$$(`${this.idPrefix}InvoicesDatatable`) as webix.ui.datatable;
        webix.extend(invoicesTable, webix.ProgressBar);
        (invoicesTable as unknown as webix.ProgressBar).showProgress(i18nHelper.getTranslation("Please_wait"));
        this.getInvoices()
        .then(invoices => {
            invoicesTable.clearAll();
            invoicesTable.define("data", invoices);
            invoicesTable.refresh();
            (invoicesTable as unknown as webix.ProgressBar).hideProgress();
        });
    }

    config2(): webix.ui.scrollviewConfig {
        return {
            view: "scrollview",
            scroll: "y",
            css: "parent-container",
            body: {
                view: "layout",
                type: "clean",
                responsive: true,

                cols: [
                    {
                        view: "layout",
                        type: "clean",
                        rows: [
                            {
                                view: "template",
                                type: "header",
                                template: "Invoices",
                                css: "invoice__header datatable-title  heading--title",
                                height: 40,
                            },
                            {
                                view: "datatable",
                                css: "datatable invoice__datatable",
                                select: "row",
                                spans: true,
                                tooltip: true,
                                autoHeight: true,
                                columns: [
                                    {
                                        id: "cost_name",
                                        header: [
                                            "INVOICE NAME",
                                            {
                                                content: "textFilter",
                                                tooltip:
                                                    "type in an invoice name",
                                            },
                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },
                                    {
                                        id: "cost_type",
                                        template: "#cost_type.name#",
                                        header: [
                                            "PROJECT TYPE",
                                            {
                                                content: "textFilter",
                                                tooltip:
                                                    "type in a project type",
                                            },
                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },
                                    {
                                        id: "project",
                                        template: "#project.name#",
                                        header: [
                                            "PROJECT NAME",
                                            {
                                                content: "textFilter",
                                                tooltip:
                                                    "type in a project name",
                                            },
                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },
                                    {
                                        id: "source_language",
                                        template: "#source_language.name#",
                                        header: [
                                            "SOURCE LANGUAGE",
                                            {
                                                content: "textFilter",
                                                tooltip:
                                                    "type in a source language",
                                            },
                                        ],
                                        fillspace: true,
                                    },
                                    {
                                        id: "target_languages_names",
                                        header: [
                                            "TARGET LANGUAGE",
                                            {
                                                content: "selectFilter",
                                                tooltip:
                                                    "type in a target language",
                                            },
                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },
                                    {
                                        id: "subject_area",
                                        template: "#subject_area.name#",
                                        header: [
                                            "SUBJECT AREA",
                                            {
                                                content: "textFilter",
                                                tooltip:
                                                    "type in a subject area",
                                            },
                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },
                                    {
                                        id: "amount",
                                        header: [
                                            "PRICE",
                                            {
                                                content: "selectFilter",
                                                tooltip: "type in a price",
                                            },
                                        ],
                                        sort: "int",
                                        fillspace: true,
                                    },
                                    {
                                        id: "cost_status",
                                        template: "#cost_status.name#",
                                        header: [
                                            "INVOICE STATUS",
                                            {
                                                content: "textFilter",
                                                tooltip:
                                                    "type in an invoice status",
                                            },


                                        ],
                                        sort: "text",
                                        fillspace: true,
                                    },

                                    { view: "spacer", width: 60 },
                                ],

                                url: () => {
                                    const costTypeId = IntranetCostType.CUSTOMER_INVOICE;
                                    return CognovisRestInvoiceService.getInvoice(
                                        { 
                                            costTypeIds: [costTypeId],
                                            //pagination:{start:0,limit:200,property:'cost_id',direction:Pagination.direction.DESC}
                                        }
                                    )
                                },
                            },
                            {
                                view: "spacer",
                                height: 50,
                                css: "spacer__invoice__view__last__row",
                            },
                        ],
                    },
                ],
            },
        };
    }

}
