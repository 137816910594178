import { IFreelancePackage, ITransWorkflowStep, ITransTask, INamedId, IntranetProjectType, IntranetTransTaskType, WebixPortalAssignmentService, IntranetTranslationLanguage, WebixPortalTranslationService, ITransProject } from "../../../../../sources/openapi";
import CognovisBasicModal from "../../../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import "./edit-batches.style.css";
import { WebixHelpers } from "../../../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { i18nHelper } from "../../../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisOkButtonWindow } from "../../../../../sources/services/cognovis-ok-button-window";
import { last } from "cypress/types/lodash";


export default class EditBatches extends CognovisBasicModal {

    projectId:number;
    newlyCreatedFreelancePackages:ICognovisNewFreelancePackage[] = [];
    existingFreelancePackages:IFreelancePackage[] = [];
    webixHelpers:WebixHelpers;
    currentlySelectedWorfkflowStep:IntranetProjectType;
    currentlySelectedTargetLanguage:IntranetTranslationLanguage;
    currentlyUnassignedTasks:{packageTypeId:IntranetTransTaskType, tasks:ITransTask[]}[] = [];
    afterCloseAction?:()=>void;
    cognovisOkWindow: CognovisOkButtonWindow;

    init(): void {
        this.webixHelpers = container.resolve(WebixHelpers);
        this.cognovisOkWindow = container.resolve(CognovisOkButtonWindow);
    }

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Edit_Batches`), 1024,780);
        return mainLayout;
    }

    openBatchesEditionModal(projectWorkflowSteps:ITransWorkflowStep[], afterCloseAction?:()=>void):void {
        if(afterCloseAction) {
            this.afterCloseAction = afterCloseAction;
        }
        this.projectId = this.getParam("project_id", true);
        const modal = (this.getRoot() as webix.ui.window);
        modal.show();
        const content = this.getContent();
        const actionButtons = this.getActionButtons();
        this.setContent(content,actionButtons);
        this.showListLoading("ebmPackagesList", `${i18nHelper.getTranslation(`Select_batch`)}...`);
        this.showListLoading("ebmUnsassignedTasksList", `${i18nHelper.getTranslation(`Select_translation_step`)}...`);
        this.setWorkflowStepCombo(projectWorkflowSteps);
        this.setLanguagesCombo();
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            id:"ebmSaveAndCloseButton", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.afterCloseAction();
                                this.closeModal();
                            }
                        },    
                    ] 
                };
        return buttons;
    }

    closeModal():void {
        super.hide();
    }

    getContent():webix.ui.layoutConfig {
        return {
                id:"batchesEditionModal",
                type: "clean",
                rows: [
                    { view: "spacer", height: 10 },

                    {
                        //type: "clean",
                        rows:[
                            //the first row of batches and + button
                            {
                                cols:[
                                    {
                                        align:"left",
                                        borderless:false,
                                        paddingX:20,
                                        body:{
                                            cols:[
                                                {
                                                    view:"richselect",
                                                    id:"ebmSelectTranslationStepCombo",
                                                    placeholder:`${i18nHelper.getTranslation(`Select_translation_step`)}`,
                                                    minWidth:200,
                                                    on: {
                                                        onChange:function(stepTypeId:IntranetTransTaskType) {
                                                            this.$scope.currentlySelectedWorfkflowStep = stepTypeId;
                                                            this.$scope.clearList("ebmPackagesList");
                                                            this.$scope.showListLoading("ebmPackagesList", `${i18nHelper.getTranslation(`Select_batch`)}`);
                                                            const currentTlSelectionCombo = webix.$$("ebmSelectTranslationLanguageCombo") as webix.ui.richselect;
                                                            const currentTlSelection = currentTlSelectionCombo.getValue();
                                                            if (currentTlSelection != "")  {
                                                                this.$scope.getUnassignedTasks(this.$scope.projectId, stepTypeId, currentTlSelection)
                                                                .then(unassignedTasks => {
                                                                    this.$scope.loadListData("ebmUnsassignedTasksList", unassignedTasks);
                                                                });
                                                                this.$scope.setPackagesCombo(stepTypeId,currentTlSelection);
                                                            }
                                                            // Enable or disable newBatchButton
                                                            const newBatchButton = webix.$$("ebmAddNewBatchButton");
                                                            if(stepTypeId && currentTlSelection != "") {
                                                                newBatchButton.enable();
                                                            } else {
                                                                newBatchButton.disable();
                                                            }
                                                        }
                                                    },
                                                },
                                                {
                                                    view:"richselect",
                                                    id:"ebmSelectTranslationLanguageCombo",
                                                    placeholder:`${i18nHelper.getTranslation(`Select_language`)}`,
                                                    minWidth:200,
                                                    on: {
                                                        onChange:function(targetLanguageId:IntranetTranslationLanguage) {
                                                            this.$scope.currentlySelectedTargetLanguage = targetLanguageId;
                                                            this.$scope.clearList("ebmPackagesList");
                                                            this.$scope.showListLoading("ebmPackagesList", `${i18nHelper.getTranslation(`Select_batch`)}`);
                                                            const currentStepSelectionCombo = webix.$$("ebmSelectTranslationStepCombo") as webix.ui.richselect;
                                                            const stepTypeId = currentStepSelectionCombo.getValue();
                                                            const newBatchButton = webix.$$("ebmAddNewBatchButton");
                                                            if(stepTypeId) {
                                                                this.$scope.getUnassignedTasks(this.$scope.projectId, stepTypeId, targetLanguageId)
                                                                .then(unassignedTasks => {
                                                                    this.$scope.loadListData("ebmUnsassignedTasksList", unassignedTasks);
                                                                });
                                                                this.$scope.setPackagesCombo(stepTypeId, targetLanguageId);
                                                                // Enable or disable newBatchButton
                                                                newBatchButton.enable();
                                                            } else {
                                                                newBatchButton.disable();
                                                            }
                                                        }
                                                    },
                                                }
                                            ]
                                        }
                                    }, 
                                    {
                                        align:"right",
                                        borderless:false,
                                        body:{
                                            view:"layout",
                                            borderless:false,
                                            paddingX:10,
                                            cols: [
                                                {
                                                    view:"richselect",
                                                    id:"ebmSelectPackageCombo",
                                                    placeholder:i18nHelper.getTranslation(`Select_batch`),
                                                    minWidth:350,
                                                    options:[],
                                                    on: {
                                                        onChange:function(freelancePackageId:number | boolean) {
                                                            const freelancePackages = this.$scope.existingFreelancePackages;
                                                            const specificPackage = freelancePackages.find(flPackage => flPackage?.freelance_package?.id.toString() === freelancePackageId.toString());
                                                            this.$scope.selectFreelancePackage(specificPackage);                                                           
                                                        }
                                                    }
                                                },
                                                { 
                                                    view: "button", 
                                                    type: "icon", 
                                                    icon: "fas fa-plus-circle", 
                                                    id: "ebmAddNewBatchButton", 
                                                    css: "add-batch-btn", 
                                                    width: 50, 
                                                    align:"right",
                                                    disabled:true,
                                                    click:() => {
                                                        this.openNewBatchModal();
                                                    }
                                                },
                                            ]
                                        }
                                    }                       
                                ],
                            },
                            {
                            cols: [
                                //tasks column
                                {
                                    rows:[
                                        {
                                            cols:[
                                                {
                                                    view:"label",
                                                    margin:10,
                                                    label:i18nHelper.getTranslation(`Unassigned_tasks`),
                                                    width:390,
                                                    height:40,
                                                    borderless:true,
                                                    css: {
                                                        "padding-left":"8px"
                                                    }
                                                },
                                                { 
                                                    view: "button", 
                                                    id:"ebmMoveItemRightButton",
                                                    width:25, 
                                                    align:"right",
                                                    type: "icon", 
                                                    css: {
                                                        "margin-left":"20px"
                                                    },
                                                    icon: "fas fa-arrow-right",  
                                                    click:() => {
                                                        const selectedTasks = this.getListSelectedItems("ebmUnsassignedTasksList");
                                                        if(selectedTasks.length > 0) {
                                                            selectedTasks.map((task:ITransTask, i:number) => {
                                                                const currentlySelectedPackage = this.getCurrentlySelectedPackage();
                                                                this.removeItemFromList("ebmUnsassignedTasksList",task["id"]);
                                                                this.addItemToList("ebmPackagesList", task);
                                                                if((i + 1) === selectedTasks.length) {
                                                                    this.saveTaskInFreelancePackage(task.task,currentlySelectedPackage);
                                                                }
                                                            });
                                                        }
                                                    }
                                                },
                                            ]
                                        },
                                        {   
                                            view: "list",
                                            drag:true,
                                            multiselect:true,
                                            select:true,
                                            id:"ebmUnsassignedTasksList",
                                            width:400,
                                            template:"#task.name#",
                                            height:400,
                                            undo:true,
                                            on:{
                                                onAfterDrop:(element) => {
                                                    const draggedItemId = element.start;
                                                    const me = webix.$$("ebmUnsassignedTasksList") as webix.ui.list;
                                                    const draggedItem = me.getItem(draggedItemId);
                                                    const task = draggedItem.task;
                                                    const currentlySelectedPackage = this.getCurrentlySelectedPackage();
                                                    this.saveTaskInFreelancePackage(task,currentlySelectedPackage);
                                                }
                                            }
                                        },
                                    ]
                                },
                                { view: "spacer", width: 10 },
                                // batches column
                                {
                                    rows:[
                                        {
                                            cols:[
                                                { 
                                                    view: "button", 
                                                    id:"ebmMoveItemLeftButton",
                                                    width:25, 
                                                    type: "icon", 
                                                    icon: "fas fa-arrow-left",  
                                                    align:"left",
                                                    click:() => {
                                                        const selectedTasks = this.getListSelectedItems("ebmPackagesList");
                                                        if(selectedTasks.length > 0) {
                                                            selectedTasks.map((task:ITransTask, i:number) => {
                                                                const currentlySelectedPackage = this.getCurrentlySelectedPackage();
                                                                this.removeItemFromList("ebmPackagesList",task["id"]);
                                                                this.addItemToList("ebmUnsassignedTasksList", task);
                                                                if((i + 1) === selectedTasks.length) {
                                                                    this.saveTaskInFreelancePackage(task.task,currentlySelectedPackage);
                                                                }
                                                            });
                                                        }
                                                    }

                                                },
                                                {
                                                    view:"label",
                                                    align:"right",
                                                    margin:10,
                                                    label:i18nHelper.getTranslation(`Batches_tasks`),
                                                    width:390,
                                                    height:40,
                                                    borderless:true,
                                                    css: {
                                                        "padding-right":"8px"
                                                    }
                                                },

                                            ]
                                        },
                                        {   
                                            view: "list",
                                            id:"ebmPackagesList",
                                            select:true,
                                            drag:true,
                                            multiselect:true,
                                            undo:true,
                                            template:"#task.name#",
                                            width:400,
                                            height:400,
                                            on:{
                                                onAfterDrop:(element) => {
                                                    const draggedItemId = element.start;
                                                    const me = webix.$$("ebmPackagesList") as webix.ui.list;
                                                    const draggedItem = me.getItem(draggedItemId);
                                                    const task = draggedItem.task;
                                                    const currentlySelectedPackage = this.getCurrentlySelectedPackage();
                                                    this.saveTaskInFreelancePackage(task,currentlySelectedPackage);
                                                }
                                            }
                                        },
                                    ]
                                },
                            ]},
                        ]

                    },
                ],
            } 
    }

    setLanguagesCombo():void {
        const projectTargetLanguagesCombo = (webix.$$("ebmSelectTranslationLanguageCombo") as webix.ui.combo);
        WebixPortalTranslationService.getTransTasks({ projectId: this.projectId })
        .then(projectTasks => {
            const langs = projectTasks.map(task => ({id:task.target_language.id, value:task.target_language.name}));
            //projectTargetLanguagesCombo.define("options", [{value:i18nHelper.getTranslation(`all`), id:-1},...langs]);
            projectTargetLanguagesCombo.define("options", langs);
            const langIds = []
            langs.forEach(lang => {
                if (langIds.indexOf(lang.id)<0) {
                    langIds.push(lang.id)
                }
            })
            if ( langIds.length === 1) {
                projectTargetLanguagesCombo.setValue(langs[0].id.toString())
            }
        });
    }

    setWorkflowStepCombo(projectWorkflowSteps:ITransWorkflowStep[]):void {
        const stepsPreparedForCombo = [];
        const projectWorkflowStepCombo = (webix.$$("ebmSelectTranslationStepCombo") as webix.ui.combo);
        projectWorkflowSteps.map(step => stepsPreparedForCombo.push({id:step.task_type.id, value:step.task_type.name}));
        projectWorkflowStepCombo.define("options", stepsPreparedForCombo);
    }

    setPackagesCombo(packageTypId:IntranetTransTaskType,targetLangId:IntranetTranslationLanguage):void {
        // First we get reference to packagesCombo and we reset current values
        const packagesCombo = (webix.$$("ebmSelectPackageCombo") as webix.ui.combo);
        packagesCombo.define("options", []);
        packagesCombo.refresh();
        // Now we need to figure out if some packages are saved to browser memory
        const packagesPreparedForCombo = [];
        if(this.existingFreelancePackages.length > 0 || this.newlyCreatedFreelancePackages.length > 0) {
            const filteredPackages = this.existingFreelancePackages.filter(flPackage => flPackage?.package_type?.id.toString() ===  packageTypId.toString());
            filteredPackages.map(flPackage => packagesPreparedForCombo.push({id:flPackage?.freelance_package?.id, value:flPackage?.freelance_package?.name}));
            packagesCombo.define("options", packagesPreparedForCombo);
            packagesCombo.refresh();
        } else {
            this.getPackages(this.projectId)
            .then(packages => {
                this.existingFreelancePackages = packages;
                const filteredPackages = packages.filter(flPackage => flPackage?.package_type?.id.toString() ===  packageTypId.toString() && flPackage?.target_language?.id.toString() === targetLangId.toString() && !flPackage?.has_assignments);
                filteredPackages.map(flPackage => packagesPreparedForCombo.push({id:flPackage?.freelance_package?.id, value:flPackage?.freelance_package?.name}));
                packagesCombo.define("options", packagesPreparedForCombo);
                packagesCombo.refresh();
            });
        }

    }

    selectFreelancePackage(freelancePackage:IFreelancePackage):void {
        const listComponentId = "ebmPackagesList";
        this.showListLoading(listComponentId);
        this.clearList(listComponentId);
        if(freelancePackage) {
            if (freelancePackage.tasks != null) {
                const packageTransTasks = freelancePackage.tasks;
                this.loadListData(listComponentId, packageTransTasks);
            }
        }
        this.hideListLoading(listComponentId);
    }

    clearList(listComponentId:string):void {
        const list = (webix.$$(listComponentId) as webix.ui.list);
        list.clearAll();
    }

    updatePackage(currentlySelectedPackageId:number):void {
        const list = (webix.$$("ebmPackagesList") as webix.ui.list);
        const currentTasks = list.serialize();
        // Now we need to figure out if currently selected package is new
        const packagesCombo = (webix.$$("ebmSelectPackageCombo") as webix.ui.combo);
        const allPackagesOptions = Object.values(packagesCombo.getList()["data"].pull)
        const newPackgeFullObject = Object.values(allPackagesOptions).find(option => option["id"].toString() === currentlySelectedPackageId.toString()) as ICognovisNewFreelancePackage;
        let currentlySelectedPackage:ICognovisNewFreelancePackage | IFreelancePackage;
        if(newPackgeFullObject?.newOne) {
            // If a package is new, we need to refer to 'this.newlyCreatedFreelancePackages'
            currentlySelectedPackage = this.newlyCreatedFreelancePackages.find(option => option.id.toString() === currentlySelectedPackageId.toString()) as ICognovisNewFreelancePackage;
        } else {
            // If a package was created before opening modal, we need to refer to 'this.existingFreelancePackages'
            currentlySelectedPackage = this.existingFreelancePackages.find(option => option.freelance_package.id === currentlySelectedPackageId);
            currentlySelectedPackage["modified"] = true;
        }
        currentlySelectedPackage.tasks = [];
        currentlySelectedPackage.tasks = currentTasks;
    }

    showListLoading(listComponentId:string, loadingText?:string):void {
        if(!loadingText) {
            loadingText = i18nHelper.getTranslation(`Loading`)
        }
        const list = (webix.$$(listComponentId) as webix.ui.list);
        webix.extend(list, webix.OverlayBox);
        (webix.$$(listComponentId))["showOverlay"](loadingText);
    }
    
    hideListLoading(listComponentId:string):void {
        const list = (webix.$$(listComponentId) as webix.ui.list);
        webix.extend(list, webix.OverlayBox);
        (webix.$$(listComponentId))["hideOverlay"]();
    }

    loadListData(listComponentId:string, unassignedTasks:IFreelancePackage[]):void {
        const list = (webix.$$(listComponentId) as webix.ui.list);
        list.define("data", unassignedTasks);
    }

    getPackages(projectId:number):Promise<IFreelancePackage[]> {
        return WebixPortalAssignmentService.getPackages({
            projectId:projectId
        })
    }

    getListSelectedItems(listComponentId:string):ITransTask[] {
        const list = (webix.$$(listComponentId) as webix.ui.list);
        const items = list.getSelectedItem(true);
        return items;
    }

    getListItems(listComponentId:string):INamedId[] {
        const list = (webix.$$(listComponentId) as webix.ui.list);
        const items = list.serialize();
        const extractedTasks = items.map(item => item.task);
        return extractedTasks;
    }

    addItemToList(listComponentId:string, item:ITransTask):void {
        const list = (webix.$$(listComponentId) as webix.ui.list);
        list.add(item)
        const currentlySelectedPackageId = this.getCurrentlySelectedPackage();
        this.updatePackage(currentlySelectedPackageId);
    }

    getCurrentlySelectedPackage():number {
        const packagesCombo = (webix.$$("ebmSelectPackageCombo") as webix.ui.combo);
        const currentlySelectedPackageId = parseInt(packagesCombo.getValue());
        return currentlySelectedPackageId;
    }

    addToUnassignedTasks(item:ITransTask):void {
        const listComponentId = "ebmUnsassignedTasksList";
        const list = (webix.$$(listComponentId) as webix.ui.list);
        list.add(item);
    }
    removeItemFromList(listComponentId:string, itemId:number):void {
        const list = (webix.$$(listComponentId) as webix.ui.list);
        list.remove(itemId);
        const packagesCombo = (webix.$$("ebmSelectPackageCombo") as webix.ui.combo);
        //const allPackagesOptions = Object.values(packagesCombo.getList()["data"].pull)
        const currentlySelectedPackageId = parseInt(packagesCombo.getValue());
        this.updatePackage(currentlySelectedPackageId);
    }

    getUnassignedTasks(projectId:number, packageTypeId:number, targetLanguageId:number):Promise<IFreelancePackage[]> {
        const listComponentId = "ebmUnsassignedTasksList";
        this.clearList(listComponentId);
        const params:{projectId:number, packageTypeId:number,targetLanguageId?:number} = {
            projectId:projectId,
            packageTypeId:packageTypeId
        };
        if(targetLanguageId > -1) {
            params.targetLanguageId = targetLanguageId
        }
        this.showListLoading(listComponentId);
        return WebixPortalAssignmentService.getTasksWithoutPackage(params)
        .then(tasksWithoutPackages => {
            const unassignedTasks = [];
            tasksWithoutPackages.map(taskWithoutPackage => {
                unassignedTasks.push(taskWithoutPackage);
            });
            this.hideListLoading("ebmUnsassignedTasksList");
            //const filteredUnassignedTasks = unassignedTasks.filter(tasksWithoutPackage => tasksWithoutPackage.task_type.id.toString() === this.currentlySelectedWorfkflowStep);
            return unassignedTasks;
        })
    }
    
    insertNewBatch(batchName:string,freelancePackageId:number):boolean {
        // Adding a new batch is only possible if we selected workflow step before
        if(this.currentlySelectedWorfkflowStep && this.currentlySelectedTargetLanguage) {
            const packagesCombo = (webix.$$("ebmSelectPackageCombo") as webix.ui.richselect);
            // Select newly created value
            const selectPackageCombo = webix.$$("ebmSelectPackageCombo") as webix.ui.richselect;
            const newItem = { id:freelancePackageId, value:batchName, name:batchName, newOne:true, tasks:[]};
            (packagesCombo.getList() as webix.ui.list).add(newItem,0);
            selectPackageCombo.setValue(freelancePackageId.toString());
            this.newlyCreatedFreelancePackages.push(newItem);
            //selectPackageCombo.setValue("2020_0621 - Q-railing_Q4less_DE_FR_fr-FR_trans");
            return true
        } else {
            return false
        }
    }

    openNewBatchModal():void {
        // Opening modal only possible if we selected workflow step before
        if(this.currentlySelectedWorfkflowStep && this.currentlySelectedTargetLanguage) {
            const modal = webix.ui({
                view:"window",
                height:280,
                width:360,
                modal:true,
                position:"center",
                head:i18nHelper.getTranslation(`New_Batch`),
                body: {
                    view:"form",
                    elements:[
                        {
                            view:"text", 
                            id:"nbmSaveNewBatchInput",
                            name: "new_batch_name", 
                            label: i18nHelper.getTranslation(`Batch_name`),
                            labelWidth:100,
                        },
                        {
                            view:"button", 
                            css:"cog-button-big",
                            id:"nbmSaveNewBatchButton",
                            label:"Ok",
                            hotkey:"enter",
                            click:() => {
                                // Figure out new package name
                                const newPackageNameField = webix.$$("nbmSaveNewBatchInput") as webix.ui.button;
                                const newPackageName = newPackageNameField.getValue();
                                WebixPortalAssignmentService.postPackages({
                                    projectId:this.projectId,
                                    requestBody:{
                                        package_type_id:this.currentlySelectedWorfkflowStep as unknown as IntranetTransTaskType,
                                        package_name:newPackageName,
                                        target_language_id:this.currentlySelectedTargetLanguage
                                    }
                                })
                                .then((res) => {
                                    const packageName = res[0].freelance_package.name
                                    const packageId = res[0].freelance_package.id
                                    this.insertNewBatch(packageName,packageId);
                                    (modal as webix.ui.window).close();
                                });

                            }
                        }
                    ]
                }
            });
            modal.show();
            const textield = modal.queryView({ view:"text"}) as webix.ui.text;
            textield.focus();
        }
    }

    saveTaskInFreelancePackage(task:INamedId, freelancePackageId:number):Promise<boolean> {
        const items = this.getListItems("ebmPackagesList");
        const onlyIds = items.map(transTask => transTask.id);
        return WebixPortalAssignmentService.putPackages({
            freelancePackageId:freelancePackageId,
            requestBody:{
                trans_task_ids:onlyIds
            } 
        })
        .then(() => {
            if(items.filter(item => item.id === task.id).length > 0) {
                this.refreshUnassignedTaskList();
            }
            return true
        })
        .catch((err) => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal",
                callback:() => {
                    this.refreshUnassignedTaskList();
                    this.refreshPackagesList();
                }
            });
            return false
        });
    }

    refreshUnassignedTaskList():void {
        const currentTlSelectionCombo = webix.$$("ebmSelectTranslationLanguageCombo") as webix.ui.richselect;
        const currentTlSelection = Number(currentTlSelectionCombo.getValue());
        const currentStepSelectionCombo = webix.$$("ebmSelectTranslationStepCombo") as webix.ui.richselect;
        const stepTypeId = Number(currentStepSelectionCombo.getValue());
        const packageCombo = webix.$$("ebmSelectPackageCombo") as webix.ui.richselect;
        const freelancePackageId = Number(packageCombo.getValue());
        const selectedPackage = this.existingFreelancePackages.find(flPackage => flPackage?.freelance_package?.id.toString() === freelancePackageId.toString());
        this.loadListData("ebmUnsassignedTasksList", []);
        this.getUnassignedTasks(this.projectId, stepTypeId, currentTlSelection)
        .then(unassignedTasks => {
            this.loadListData("ebmUnsassignedTasksList", unassignedTasks);
        });
    }

    refreshPackagesList():void {
        const lastSelectedPackageId = this.getCurrentlySelectedPackage();
        const lastSelectedPackage = this.existingFreelancePackages.find(flPackage => flPackage.freelance_package.id === lastSelectedPackageId);
        if(lastSelectedPackage) {
            this.selectFreelancePackage(lastSelectedPackage);
        } 
    }
}
