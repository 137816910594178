import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { CognovisRestNotesService, IntranetNotesType, ITransProject, WebixPortalTranslationService } from "../../../sources/openapi";
import { config } from "../../config";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import ObjectNotesModal from "views/modals/object-notes-modal";

export default class ContactAgainModal extends CognovisBasicModal {

    idPrefix = "cam";
    currentProject:ITransProject;
    callbackAction:() => void;
    webixHelpers: WebixHelpers;
    contactAgainNoteModal:ObjectNotesModal;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ", 360,190);
        return mainLayout;
    }

    openModal(project:ITransProject, callbackAction?:() => void):void {
        this.webixHelpers = container.resolve(WebixHelpers);
        if(project) {
            this.currentProject = project;
        }
        if(!this.contactAgainNoteModal) {
            this.contactAgainNoteModal = this.ui(ObjectNotesModal) as ObjectNotesModal;
        }
        if(callbackAction) {
            this.callbackAction = callbackAction;
        }
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const modalContent = this.getContent(project);
        const modalTitle = `${project.project.name}: ${i18nHelper.getTranslation("Update_contact_again_date")}`;
        const actionButtons = this.getActionButtons();
        this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle);
    }

    closeModal():void {
        this.hide();
    }

    getContent(project:ITransProject):webix.ui.layoutConfig {
        let contactAgainDate:Date;
        if(!project.start_date) {
            contactAgainDate = new Date();
        } else {
            contactAgainDate = new Date(project.start_date);
        }
        contactAgainDate.setDate(contactAgainDate.getDate()+config.defaultContactAgainDays);
        const layout = {
            view:"scrollview",
            body:{
                padding:30,
                rows:[
                    {
                        view:"layout",
                        localId:`${this.idPrefix}ContactAgainContainer`,
                        rows:[
                            {
                                view:"datepicker",
                                localId:`${this.idPrefix}ContactAgainDatepicker`,
                                value:contactAgainDate,
                                format:"%d.%m.%Y",
                            }
                        ]
                    },
                ]
            }
        };
        return layout
    }

    

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Cancel_and_go_back`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`,
                            align: "right",
                            click:() => {
                                const contactAgainDateField = this.$$(`${this.idPrefix}ContactAgainDatepicker`) as webix.ui.datepicker;
                                const contactAgainDate = contactAgainDateField.getValue();
                                this.updateContactAgainDate(this.currentProject, contactAgainDate)
                            }
                        },    
                    ] 
                };
        return buttons;
    }

    updateContactAgainDate(project:ITransProject, newContactAgainDate:string):void {
        const parsedContactAgainDate = this.webixHelpers.formatDate(new Date(newContactAgainDate));
        WebixPortalTranslationService.putTransProject({
            projectId:project.project.id,
            requestBody:{
                contact_again_date:parsedContactAgainDate,
                company_id:project.company.id,
                source_language_id:project.source_language.id,
                target_language_ids:project.target_language.map(tl => tl.id)
            }
        })
        .then(() => {
            this.contactAgainNoteModal.openNotesModal("contact-again-note", i18nHelper.getTranslation("Contact again note"), {} as unknown,"", "cke5", [],
            (inputedNote) => {
                CognovisRestNotesService.postNote({
                    objectId:project.project.id,
                    requestBody:{
                        note_type_id:IntranetNotesType.MEETING_NOTE,
                        note:inputedNote
                    }
                })
                .then(() => {
                    this.close();
                    this.callbackAction();
                })
            });
        })
    }


}