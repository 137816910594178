import { IInvoiceItem, ImTransTaskIds, IInvoice, WebixPortalTranslationService, ImInvoiceIds } from '../../../openapi';
import { JetView } from 'webix-jet'
import { CognovisRestInvoiceService } from '../../../openapi';
import { CognovisPleaseWaitWindow } from '../../../../sources/services/cognovis-please-wait-window';
import { container } from 'tsyringe';
import { CognovisNavigator } from '../../../../sources/modules/cognovis-navigator/cognovis-navigator';
import { WebixHelpers } from '../../../../sources/modules/webix-helpers/webix-helpers';
import { i18nHelper } from "../../../modules/i18n-helper/i18n-helper";

export default class ReplaceQuoteModal extends JetView {

    quotesDataContainerName = "quotes-container";
    selectedTasksIds: ImTransTaskIds;
    cognovisPleaseWaitWindow:CognovisPleaseWaitWindow;
    webixHelpers: WebixHelpers;
    projectId:number;

    config(): webix.ui.windowConfig {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return {
            view: "window",
            modal: true,
            head: i18nHelper.getTranslation("Replace_quotes"),
            position: "center",
            width: 640,
            minHeight: 480,
            id: "replaceQuoteModal",
            css: "container replace-quote-container",
            body: ({
                view: "layout",
                type: "clean",
                cols: [
                    { view: "spacer", width: 30 },
                    {
                        view: "layout",
                        type: "clean",
                        rows: [
                            { view: "template", template: i18nHelper.getTranslation("lt_You_are_about_to_repl"), height: 30 },
                            { view: "template", template: i18nHelper.getTranslation("lt_Please_select_which_o"), height: 30 },
                            { view: "spacer", height: 5 },
                            {
                                view: "popup",
                                id: "quote-popup",
                                hidden: false,
                                minWidth: 520,
                                minHeight: 400,
                                scroll: true,
                                padding: 0,
                                margin: 10,
                                body: {
                                    view: "template",
                                    height: 400,
                                    autowidth: true,
                                    css: 'popup__main',
                                    scroll: true
                                },
                            },
                            {
                                view: "layout", id: "quotes-container", rows: [

                                ]
                            },
                            { view: "spacer", height: 20 },
                            {
                                view: "layout", type: "clean", cols: [
                                    {
                                        view: "button", value: i18nHelper.getTranslation("Yes_replace_selected"), id: "replace", css: "button", inputWidth: 200,
                                        click: () => {
                                            this.attemptToReplaceQuote();
                                        }
                                    },
                                    {
                                        view: "button", value: i18nHelper.getTranslation("No_keep_existing"), id: "keep", css: "button", inputWidth: 200,
                                        click: () => (this.getRoot() as webix.ui.window).hide()
                                    }
                                ]
                            },
                            { view: "spacer", height: 20 },
                        ]
                    }
                ]
            } as unknown) as string
        }
    }

    showModal(projectId: number): void {
        this.projectId = projectId;
        this.getProjectQuotes(projectId)
            .then(quotes => {
                this.setQuotesData(quotes);
                const modal = (this.getRoot() as webix.ui.window);
                modal.show();
            })
    }

    getProjectQuotes(projectId: number): Promise<IInvoice[]> {
        return CognovisRestInvoiceService.getInvoice(
            { 
                projectId: projectId,
                costTypeIds: [3702]
            }
        )
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
            return []
        });
    }

    setQuotesData(quotes: IInvoice[]): void {
        const quotesContainer = ($$(this.quotesDataContainerName) as webix.ui.layout);
        const quotesRows = [];
        // Pre-select quote if there is only one
        let checked = false;
        if(quotes.length === 1) {
            checked = true;
        }
        quotes.forEach((quote: IInvoice) => {
            quotesRows.push(
                {
                    view: 'layout',
                    type: 'clean',
                    width: 600,
                    id: `specific-q-${quote.invoice.id}`,
                    left: 0,
                    cols: [
                        {
                            view: "checkbox",
                            id: `specific-quote-${quote.invoice.id}`,
                            quote_data:quote,
                            labelRight: quote.invoice.name,
                            value: checked,
                            labelWidth: 0,
                            left: 0,
                            width: 140
                        },
                        {
                            view: 'icon',
                            icon: 'fa fa-info',
                            id: `specific-quote-icon-${quote.invoice.id}`,
                            on: {
                                onItemClick: () => {
                                    this.getLineItems(quote.invoice.id)
                                        .then(lineItems => {
                                            const htmlWithLineItems = this.buildLineItemsHtml(quote.invoice.name, lineItems)
                                            const quoteDetailsPopup = ($$(`quote-popup`) as unknown as webix.ui.popup);
                                            const popupBody = quoteDetailsPopup.getBody();
                                            popupBody.define("template", htmlWithLineItems);
                                            popupBody.refresh();
                                            quoteDetailsPopup.show($$(`specific-quote-icon-${quote.invoice.id}`).getNode(), {
                                                x: 0,
                                                y: 0
                                            });

                                        })
                                }
                            },
                            popup: 'quote-popup',

                        }
                    ]
                }
            );
        });
        quotesContainer.define("rows", quotesRows);
        quotesContainer.reconstruct();
    }

    async getLineItems(quoteId: number): Promise<IInvoiceItem[]> {
        const res = await CognovisRestInvoiceService.getInvoiceItem(
            { invoiceId: quoteId }
        );
        return res;
    }

    buildLineItemsHtml(quoteName: string, items: IInvoiceItem[]): string {
        let htmlWithLineItems = `<h4>${quoteName}</h4>`;
        items.forEach((item, index) => {
            const name = `${item.item.name} (${item.item_material.name})`;
            const calculateTotal = item.price_per_unit * item.item_units;
            const calculatedTotalParsed = calculateTotal.toFixed(2);
            const price = `${item.currency} ${calculatedTotalParsed}`;
            htmlWithLineItems = htmlWithLineItems + `<div id="quote-checkbox-${item.item.id}"> ${index + 1}. ${name} - <span class="price">${price}</span></div><br/>`;
        });
        return htmlWithLineItems;

    }

    attemptToReplaceQuote(): void {
        const selectedQuotes = this.getSelectedQuotes();
        // Extract cost_ids
        const selectedQuotesIds:ImInvoiceIds = selectedQuotes.map(quote => quote.invoice.id);
        if (selectedQuotes.length > 1) {
            
            this.replaceMultipleQuotes(selectedQuotesIds);
        } else {
            if(selectedQuotesIds[0]) {
                this.replaceSingleQuote(selectedQuotesIds[0]);
            }
        }
    }

    getSelectedQuotes(): IInvoice[] {
        const quotesContainer = ($$(this.quotesDataContainerName) as webix.ui.layout);
        const checkboxesHolder = quotesContainer.getChildViews();
        const onlySelectedQuotesArr = [];
        checkboxesHolder.forEach(checkboxHolder => {
            if (checkboxesHolder) {
                const quotesElements = checkboxHolder.getChildViews();
                quotesElements.forEach(quote => {
                    // If item is selected
                    if (quote.getValue()) {
                        // Extract only "quote_data" to match the type of IInvoice
                        onlySelectedQuotesArr.push(quote.config.quote_data);
                    }
                });           
            }
        });
        return onlySelectedQuotesArr;
    }

    replaceSingleQuote(quoteId: number): void {
        this.cognovisPleaseWaitWindow.show({message: i18nHelper.getTranslation("lt_Quote_is_beign_update")})
        WebixPortalTranslationService.putTransQuote({
                quoteId: quoteId,
                requestBody:{trans_tasks: this.selectedTasksIds}
            }
        )
        .then(response => {
            const quoteId = response[0].invoice.id;
            CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${quoteId}&project_id=${this.projectId}`);
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        })
        .finally(() => {
            this.cognovisPleaseWaitWindow.hide();
        });
    }

    replaceMultipleQuotes(selectedQuotesIds: ImInvoiceIds): void {
        this.cognovisPleaseWaitWindow.show({message:i18nHelper.getTranslation("lt_Quotes_are_beign_repl")});
        CognovisRestInvoiceService.deleteInvoice({
            invoices:selectedQuotesIds
        })
        .then(() => {
            // "Deleting" was a success, we now create new quote
            WebixPortalTranslationService.postTransQuote({
                requestBody: {
                    trans_tasks: this.selectedTasksIds,
                    quote_per_language_p: false
                }
            })
            .then((res) => {
                if (res[0]) {
                    const newInvoiceId = res[0].invoice.id;
                    CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${newInvoiceId}&project_id=${this.projectId}`);
                }
            })
            .catch(err => {
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            })
            .finally(() => {
                this.cognovisPleaseWaitWindow.hide();
            });
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        })
        .finally(() => {
            this.cognovisPleaseWaitWindow.hide();
        });
    }


}