import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { config } from '../../../sources/config';
import { IJetApp, JetView } from "webix-jet";
import { CognovisRestCompanyService, CognovisRestPricingService, ICompany, IntranetPriceStatus } from "../../../sources/openapi";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";
import PriceDetailsModal from "./price-details-modal";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { DatetimeHelper } from "../../../sources/modules/datetime-helpers/datetime-helpers";
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";

export default class PriceListTable extends CognovisBasicPortlet {

    idPrefix = "plt";
    companyId:number;
    webixHelpers:WebixHelpers;
    companyData:ICompany;
    priceDetailsModal:PriceDetailsModal;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    portletId:number;
    title:string;

    constructor(app:IJetApp, portletId?:number, title?:string) {
        super(app, {});
        this.portletId = portletId;
        if(title) {
            this.title = title;
        }
    }

    init():void {
        super.init();
        this.companyId = this.getParam("company_id", false);
        this.webixHelpers = container.resolve(WebixHelpers);
        CognovisRestCompanyService.getCompanies({
            companyId:this.companyId
        })
        .then(companies => {
            if(companies[0]) {
                this.companyData = companies[0];
            }
            this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
            const layout = {
                view:"layout",
                minHeight:320,
                rows:[
                    this.getPricesTable(),
                    this.getCsvUploader()
                ]
            }
            this.components = [layout]
            this.insertComponents();
            this.refreshPricesData();
        });
    }
    
    getHeader():webix.ui.layoutConfig {
        return {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: this.title, 
                    borderless: true
                },
                { 
                     view: "button", 
                     type: "icon", 
                     icon: "far fa-file-excel", 
                     align: "right", 
                     css:"action-icon-grey",
                     width: 28, 
                     borderless: true ,
                     on: {
                        onItemClick: () => {
                            const bearerToken = webix.storage.local.get("cognovis_bearer_token");
                            window.location.href = `${config.restUrl}/price_list?company_id=${this.companyId}&api_key=${bearerToken}`;
                        }
                    }
                },
                { 
                    view: "button", 
                    type: "icon", 
                    icon: "far fa-file-upload", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true ,
                    on: {
                        onItemClick: () => {
                            this.openPriceUploader();
                        }
                    }
                },
                { 
                    view: "button", 
                    type: "icon", 
                    icon: "fal fa-plus", 
                    align: "right", 
                    css:"action-icon-grey",
                    width: 28, 
                    borderless: true ,
                    click:() => {
                        if(!this.priceDetailsModal) {
                            this.priceDetailsModal = this.ui(PriceDetailsModal) as PriceDetailsModal;
                        }
                        this.priceDetailsModal.openModal(this.companyData, () => {
                            this.refreshPricesData();
                        });
                    }
                }
            ]
        };
    }

    getCsvUploader():webix.ui.uploaderConfig {
        return {
            view: "uploader",
            id: `${this.idPrefix}-CsvUploader`,
            width: 0,
            height: 1,
            upload: this.webixHelpers.getUrlWithApiKey(
                "upload_fs_file"
            ),
            on: {
                onUploadComplete: (
                    file
                ) => {
                    const csvFile = file as unknown as IUploadedFile
                    CognovisRestPricingService.postPriceListFromFile({
                        path: file.path,
                        filename: file.filename,
                        companyId: this.companyId
                    })
                    .then(() => {
                        this.refreshPricesData();
                    })
                    .catch(err => {
                        webix.alert({
                            title: err.message,
                            text: err.body.message,
                            type: "alert-error",
                            width: 500,
                            css:"cog-remove-tasks-modal"
                        });
                    });
                },
            },
        };
    }

    getPricesTable():webix.ui.datatableConfig {
        return {
            view:"datatable",
            gravity:0.4,
            id:`${this.idPrefix}CompanyPricesTable`,
            columns:[
                {
                    name:"price_id",
                    map:"#price_id#",
                    hidden:true
                },
                {
                    name:"uom",
                    header: [i18nHelper.getTranslation("Material")],
                    map:"#material.material_uom?.name#"
                },
                {
                    name:"material_name",
                    header: [i18nHelper.getTranslation("Material")],
                    map:"#material.material?.name#",
                    fillspace:true
                },
                {
                    name:"price_complexity_type",
                    header: [i18nHelper.getTranslation("Complexity")],
                    map:"#complexity_type?.name#",
                },
                {
                    name:"price_per_unit",
                    header: [i18nHelper.getTranslation("Price")],
                    map:"#price#",
                    template:(obj) => {
                        if(obj.price) {
                            return PriceHelper.formatPrice(obj.price);
                        } else {
                            return ""
                        }
                    }
                },
                {
                    name:"price_min_price",
                    header: [i18nHelper.getTranslation("Min_price")],
                    map:"#min_price#",
                    template:(obj) => {
                        if(obj.min_price) {
                            return PriceHelper.formatPrice(obj.min_price);
                        } else {
                            return ""
                        }
                    }
                },
                {
                    name:"currency",
                    hidden:true,
                    map:"#currency#",
                    header: [i18nHelper.getTranslation("Currency")],
                },   
                {  
                    name:"actions-edit",
                    width:32,
                    tooltip:false,
                    template:() => {
                        return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-edit'></span>`;
                    }
                },
                {  
                    name:"actions-delete",
                    width:32,
                    tooltip:false,
                    template:() => {
                        return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-trash'></span>`;
                    }
                }
            ],
            onClick:{
                "fa-edit":(event,row) => {
                    const table = webix.$$(`${this.idPrefix}CompanyPricesTable`) as webix.ui.datatable;
                    const priceData = table.getItem(row);
                    if(!this.priceDetailsModal) {
                        this.priceDetailsModal = this.ui(PriceDetailsModal) as PriceDetailsModal;
                    }
                    this.priceDetailsModal.openModal(this.companyData, () => {
                        this.refreshPricesData();
                    },priceData);
                },
                "fa-trash":(event, row) => {
                    const table = webix.$$(`${this.idPrefix}CompanyPricesTable`) as webix.ui.datatable;
                    const item = table.getItem(row);
                    const confirmWarning = i18nHelper.getTranslation("lt_You_want_to_remove_th") + `<br/>`;
                    webix.confirm({
                        title: i18nHelper.getTranslation("any_are_you_sure_message"),
                        type: "confirm-warning",
                        text: confirmWarning,
                        width: 480
                    })
                    .then(() => {
                        const today = new Date();
                        const yesterday = new Date(today.setDate(today.getDate() - 1));
                        const yesterdayParsed = DatetimeHelper.parseDateFromDatepicker(today);
                        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                        CognovisRestPricingService.deletePrice({
                            priceId:item.price_id
                        })
                        .then(() => {
                            this.refreshPricesData();
                        })
                        .catch(err => {
                            webix.alert({
                                title: err.message,
                                text: err.body.message,
                                type: "alert-error",
                                width: 500,
                                css:"cog-remove-tasks-modal"
                            });
                        })
                        .finally(() => {
                            this.cognovisPleaseWaitWindow.hide();
                        });
                    });
                }
            }
        };
    }

    openPriceUploader(): void {
        const csvUploader = webix.$$(`${this.idPrefix}-CsvUploader`) as webix.ui.uploader
        csvUploader.fileDialog();
    }

    refreshPricesData():void {
        const table = webix.$$(`${this.idPrefix}CompanyPricesTable`) as webix.ui.datatable;
        const today = DatetimeHelper.parseDateFromDatepicker(new Date());
        if(table) {
            table.clearAll();
            webix.extend(this.$$(`${this.idPrefix}CompanyPricesTable`), webix.ProgressBar);
            (table as webix.ui.datatable & {showProgress:(info:string) => void}).showProgress(i18nHelper.getTranslation("Please_wait"));
            CognovisRestPricingService.getPrice({
                companyId:this.companyId,
                priceStatusId: IntranetPriceStatus.ACTIVE
            })
            .then(prices => {
                table.define("data", prices);
                table.refresh();
                table.hideOverlay();
                if(prices.length === 0) {
                    (table as webix.ui.datatable & {showOverlay:(message:string) => void}).showOverlay(i18nHelper.getTranslation("No data"));
                }
            });
        }
    }
}