import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import ProjectDetails from "views/project-details";
import { CognovisCategory } from "../../../sources/modules/cognovis-category/cognovis-category";
import {IFreelancer, ITransProjectFilter, ITransWorkflowStep, ITransProject, ImProjectId, WebixPortalAssignmentService, WebixPortalTranslationService } from "../../../sources/openapi";
import { container } from "tsyringe";
import GenerateAssignment from "./modals/generate-assignment/generate-assignment";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { PriceHelper } from "../../../sources/modules/price-helpers/price-helpers";
import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";

export default class FreelancerSelection extends ProjectDetails {
    
    projectId:number;
    idPrefix = "fs";
    projectData: ITransProject;
    projectMatchedFreelancers:IFreelancer[] = [];
    inputWidth = 250;
    labelWidth = 200;
    allPossibleFreelancers:IFreelancer[];
    webixHelpers:WebixHelpers;
    startingFilters: ITransProjectFilter;
    generateAssignmentModal:GenerateAssignment;
    projectWorkflowSteps:ITransWorkflowStep[];
    autoFocusId:string;
    assignmentsCreationPossile = false;
    lastUsedFilterValues = {
        source_language: "", 
        target_language: "", 
        skill: "", 
        cat_tool: "", 
        role: "", 
        subject_area: "" 
    };
    lastUsedPriceUomFilter:string | number = "";

    config():Promise<webix.ui.scrollviewConfig> {
        this.projectId = this.getParam("project_id", true);
        const focusData = this.getParam("auto_focus_id", true);
        this.autoFocusId = focusData;
        return WebixPortalTranslationService.getTransProjects({
            projectId:this.projectId
        })
        .then(res => {
            this.projectData = res[0];
        })
        .then(() => {
            return WebixPortalAssignmentService.getProjectWorkflowSteps({
                projectId:this.projectId
            })
            .then(workflowSteps => {    
                return WebixPortalTranslationService.getTransTasks({ projectId: this.projectId })
                .then(projectTasks => {
                    if(projectTasks.length > 0) {
                        this.assignmentsCreationPossile = true;
                    }
                    this.projectWorkflowSteps = workflowSteps;
                    const layout = this.getLayout([], workflowSteps);
                    return layout;
                });
            })
        })
    }

    init():void {
        this.webixHelpers = container.resolve(WebixHelpers);
        this.clearFreelancerTable();
        this.setDefaultPriceUom()
        .then(() => {
            this.getProjectDefaultFilters(this.projectId)
            .then(defaultFilters => {
                this.setFilterValues(defaultFilters);
            })
            .then(() => {
                this.getFreelancers(this.projectId)
                .then(freelancers => {
                    this.setFreelancers(freelancers);
                });
            });      
        });
    }

    getLayout(projectMatchedFreelancers:IFreelancer[], workflowSteps?:ITransWorkflowStep[]): webix.ui.scrollviewConfig {
        const heightSpacer = (spacerHeight: number): webix.ui.spacerConfig => ({ view: "spacer", height: spacerHeight });
        const freelancersTableView = this.getFreelancersTableView(projectMatchedFreelancers, workflowSteps);
        return {
            view: "scrollview",
            scroll: "y",
            body: {
                padding:13,
                css:"cog-content",
                rows: [
                    this.getFiltersView(), 
                    heightSpacer(20), 
                    this.getFreelancersTableOptionsView(), 
                    freelancersTableView
                ],
            },
        };
    }


    getFreelancersTableView(projectMatchedFreelancers:IFreelancer[], workflowSteps?:ITransWorkflowStep[]):webix.ui.layoutConfig  {
        const workedWithColumns = this.getWorkedWithColumns(workflowSteps);
        const freelancerTable: webix.ui.layoutConfig = {
            view: "layout",
            rows: [
                {
                    view: "datatable",
                    tooltip: true,
                    data:projectMatchedFreelancers,
                    id:"selectFreelancersTable",
                    columns: [
                        {
                            id: "selected",
                            translation_key: "NULL",
                            header: ["#", { content: "masterCheckbox" }],
                            template: "<div cog-checkbox='cog-checkbox'>{common.checkbox()}</div>",
                            fillspace: false,
                            width: 40
                        },
                        {
                            view: "template",
                            header:"",
                            width: 40,
                            tooltip:function(obj) {
                                return obj.freelancer_status_description;
                            },
                            template: function (obj) {
                                return `<span style='color:${obj.freelancer_status_color};' class='fas fa-square-full'></span>`;
                            },
                        },
                        {
                            view: "template",
                            header:"",
                            id:"freelancerName",
                            width: 40,
                            tooltip:false,
                            template: function () {
                                return `<span style='cursor:pointer;' class='fas fa-info-circle blue-box-fa--icon color-blue'></span>`;
                            },
                        },
                        { 
                            id: "name", 
                            header: i18nHelper.getTranslation("Freelancer_name"), 
                            fillspace: true, 
                            map:"#freelancer.name#" 
                        },
                        { 
                            id: "quality", 
                            header: i18nHelper.getTranslation("Quality"), 
                            tooltip:function(obj) {
                                if(obj.rating) {
                                    return obj.rating;
                                } else {
                                    return 0;
                                }
                            },
                            template:function(obj) {
                                let rating = obj.rating;
                                if(rating < 1) {
                                    rating = 0;
                                }
                                const percentage = ((rating*100)/5);
                                if(rating > 0) {
                                    return `<li style='margin-top:13px;'><div style='cursor:pointer' class="stars"><div class="percent" style="width: ${percentage}%;"></div></div></li></ul>`;
                                } else {
                                    return `<li style='margin-top:13px;'><div class="stars"><div class="percent" style="width: ${percentage}%;"></div></div></li></ul>`;
                                }
                            }
                        },
                        ...workedWithColumns,
                        { 
                            id: "fee", 
                            header: i18nHelper.getTranslation("Fee"), 
                            fillspace: true, 
                            sort:this.sortDatatableByPriceObj,
                            template:(freelancer:IFreelancer) => {
                                if(freelancer?.fee != null && freelancer?.fee[0]) {
                                    return PriceHelper.formatPrice(freelancer?.fee[0].rate, freelancer?.fee[0]?.currency);
                                } else {
                                    return "";
                                }
                                
                            } 
                        },
                    ],
                    on: {
                        onItemClick:(row) => {
                            if(row.column === "quality") {
                                const table = webix.$$(`selectFreelancersTable`) as webix.ui.datatable;
                                const item = table.getItem(row);
                                if(item.rating) {
                                    CognovisNavigator.navigateTo(`main/freelancers.freelancer-rating-overview?freelancer_id=${item.freelancer.id}`)
                                }
                            }
                        }
                    },
                    onClick:{
                        "fa-info-circle":function(e,row) {
                            const item = this.getItem(row);
                            const freelancerId = item.freelancer.id;
                            const fullUrl = window.location;
                            const freelancerUrl = `/#!/user-details?user_id=${freelancerId}`;
                            // eslint-disable-next-line security/detect-non-literal-fs-filename
                            window.open(freelancerUrl, '_blank').focus();
                        }
                    }
                },
            ],
        };
        return freelancerTable;
    }

    getFiltersView():webix.ui.accordionConfig {
        const inputWidth = 250;
        const labelWidth = 200;
        const filtersView: webix.ui.accordionConfig = {
            view: "accordion",
            multi: true,
            rows: [
                {
                    header: `${i18nHelper.getTranslation("Filter_Options")}`,
                    collapsed: false,
                    body: {
                        view: "form",
                        id:"freelancersFilterForm",
                        elements: [
                            {
                                view: "layout",
                                cols: [
                                    {
                                        view:"spacer",
                                        width:13
                                    },
                                    {
                                        rows: [
                                            {
                                                view:"multiselect",
                                                name: "source_language",
                                                id:"sfSourceLanguageCombo",
                                                placeholder: `${i18nHelper.getTranslation("source_language")}`,
                                                label:`${i18nHelper.getTranslation("source_language")}:`,
                                                labelAlign: "left",
                                                labelPosition: "top",
                                                inputWidth,
                                                labelWidth,
                                                on: {
                                                    onChange:() => {
                                                        this.toggleFilterButton();
                                                    }
                                                },
                                            },
                                            {
                                                view:"multiselect",
                                                name: "target_language",
                                                placeholder: `${i18nHelper.getTranslation("target_language")}`,
                                                label: `${i18nHelper.getTranslation("target_language")}:`,
                                                labelAlign: "left",
                                                labelPosition: "top",
                                                inputWidth,
                                                labelWidth,
                                                on: {
                                                    onChange:function(values:string[], previusValues:string[]) {
                                                        if(values.length === 0) {
                                                            webix.alert({
                                                                title: i18nHelper.getTranslation("Error"),
                                                                text: i18nHelper.getTranslation("Select at least one target language"),
                                                                minWidth:500,
                                                                type: "alert-error",
                                                            });
                                                            this.setValue(previusValues);
                                                        } else {
                                                            this.$scope.toggleFilterButton();
                                                        }
                                                    }   
                                                }
                                            },
                                            {
                                                view:"multicombo",
                                                name: "skill",
                                                placeholder: `${i18nHelper.getTranslation("skill")}`,
                                                label: `${i18nHelper.getTranslation("skill")}:`,
                                                labelAlign: "left",
                                                labelPosition: "top",
                                                inputWidth,
                                                labelWidth,
                                                suggest: {
                                                    body: {
                                                        url: () => CognovisCategory.getCategory("Intranet Skill Business Sector"),
                                                    },
                                                },
                                                on: {
                                                    onChange:() => {
                                                        this.toggleFilterButton();
                                                    }
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        rows: [
                                            // {
                                            //     view:"multiselect",
                                            //     name: "skill",
                                            //     placeholder: `${i18nHelper.getTranslation("skill")}`,
                                            //     label: `${i18nHelper.getTranslation("skill")}:`,
                                            //     labelAlign: "left",
                                            //     labelPosition: "top",
                                            //     inputWidth,
                                            //     labelWidth,
                                            //     suggest: {
                                            //         body: {
                                            //             url: () => CognovisCategory.getCategory("Intranet Skill Business Sector"),
                                            //         },
                                            //     },
                                            // },
                                            {
                                                view:"multicombo",
                                                name: "cat_tool",
                                                placeholder: `${i18nHelper.getTranslation("CAT_TOOL")}`,
                                                label: `${i18nHelper.getTranslation("CAT_TOOL")}:`,
                                                labelAlign: "left",
                                                labelPosition: "top",
                                                inputWidth,
                                                labelWidth,
                                                suggest: {
                                                    body: {
                                                        url: () => CognovisCategory.getCategory("Intranet TM Tool"),
                                                    },
                                                },
                                                on: {
                                                    onChange:() => {
                                                        this.toggleFilterButton();
                                                    }
                                                }
                                            },
                                            {
                                                view:"multicombo",
                                                name: "role",
                                                id:"sfRoleCombo",
                                                placeholder: `${i18nHelper.getTranslation("Role")}`,
                                                label: `${i18nHelper.getTranslation("Role")}:`,
                                                labelAlign: "left",
                                                labelPosition: "top",
                                                inputWidth,
                                                labelWidth,
                                                suggest: {
                                                    body: {
                                                        url: () => CognovisCategory.getCategory("Intranet Skill Role"),
                                                    },
                                                },
                                                on: {
                                                    onChange:() => {
                                                        this.toggleFilterButton();
                                                    }
                                                }
                                            },
                                            {
                                                view:"multiselect",
                                                name: "subject_area",
                                                placeholder: `${i18nHelper.getTranslation("subject_area")}`,
                                                label: `${i18nHelper.getTranslation("subject_area")}:`,
                                                labelAlign: "left",
                                                labelPosition: "top",
                                                inputWidth,
                                                labelWidth,
                                                suggest: {
                                                    body: {
                                                        url: () => CognovisCategory.getCategory("Intranet Translation Subject Area"),
                                                    },
                                                },
                                                on: {
                                                    onChange:() => {
                                                        this.toggleFilterButton();
                                                    }
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        width: 300,
                                        rows: [
                                            {
                                                view:"spacer",
                                                height:55
                                            },
                                            {
                                                view: "button",
                                                id:"fsFilterButton",
                                                label: `${i18nHelper.getTranslation("Filter")}`,
                                                align: "right",
                                                css:"cog-button-big-red disabled",
                                                inputWidth: 200,
                                                height:50,
                                                tooltip:() => {
                                                    if(this.areFilterValuesDifferent()) {
                                                        return i18nHelper.getTranslation("lt_Click_to_filter_freel");
                                                    } else {
                                                        return i18nHelper.getTranslation("lt_Please_make_changes_to");
                                                    }
                                                },
                                                click: function() { 
                                                    if(this.$scope.areFilterValuesDifferent()) {
                                                        this.$scope.clearFreelancerTable();
                                                        this.$scope.getFreelancers(this.$scope.projectId)
                                                        .then(filteredFreelancers => {
                                                            this.$scope.setFreelancers(filteredFreelancers);
                                                        });
                                                    }
                                                },
                                            },
                                            {
                                                view:"spacer",
                                                height:55
                                            },
                                        ],
                                    },
                                    {
                                        view:"spacer",
                                        width:13
                                    }
                                ],
                            },
                        ],
                    },
                },
            ],
        };
        return filtersView;
    }

    clearFreelancerTable():void {
        const table = (webix.$$("selectFreelancersTable")) as webix.ui.datatable;
        table.clearAll();
        table.showOverlay(i18nHelper.getTranslation("Please_wait"));
    }

    toggleFilterButton():void {
        const filterButton = webix.$$(`fsFilterButton`) as webix.ui.button;
        const filterButtonHtmlNode = filterButton.getNode();
        if(this.areFilterValuesDifferent()) {
            webix.html.removeCss(filterButtonHtmlNode, "disabled");
        } else {
            webix.html.addCss(filterButtonHtmlNode, "disabled");
        }
    }

    getFreelancersTableOptionsView():webix.ui.layoutConfig {
        const freelancersTableOptionsView: webix.ui.layoutConfig = {
            view: "layout",
            css:"cog-box-content",
            height:70,
            padding:13,
            cols: [
                {
                    rows: [
                        {
                            view: "button",
                            id:"fsAssignTasks",
                            value:i18nHelper.getTranslation("Assign_a_task"),
                            css: this.assignmentsCreationPossile ? "cog-button-big" : "cog-button-big disabled",
                            height:40,
                            width: 200,
                            tooltip:() => {
                                if(this.assignmentsCreationPossile) {
                                    return i18nHelper.getTranslation("Create_new_assignments");
                                } else {
                                    return i18nHelper.getTranslation("Please_create_tasks_first");
                                }
                            },
                            click:() => {
                                if(this.assignmentsCreationPossile) {
                                    this.createAssignments(this.projectId);
                                }
                            }
                        },
                    ],
                },
                {
                    view: "form",
                    borderless: true,
                    css:"cog-searchfl-form",
                    width:this.inputWidth+32,
                    elements: [
                        { 
                            view:"text", 
                            type: "search", 
                            name: "searchFreelancer", 
                            id:"freelancersFilterByNameInput",
                            placeholder: i18nHelper.getTranslation("Search_Freelancer"), 
                            inputWidth:this.inputWidth, 
                            inputHeight: 40,
                            on: {
                                onTimedKeyPress:() => this.filterFreelancersByName()
                            }
                        }
                    ],
                },
                {
                    view:"combo",
                    localId:`${this.idPrefix}UnitOfMeasurePriceFilter`,
                    name:"UoM",
                    inputWidth:this.inputWidth,
                    inputHeight: 40,
                    placeholder: i18nHelper.getTranslation("Price_unit_of_measure"), 
                    suggest: {
                        body: {
                            url: () => CognovisCategory.getCategory("Intranet UoM")
                        },
                    },
                    on: {
                        onChange:(value:string) => {
                            this.clearFreelancerTable();
                            const me = this.$$(`${this.idPrefix}UnitOfMeasurePriceFilter`) as webix.ui.combo;
                            const selectedUom = {
                                id:Number(me.getValue()),
                                category_id:Number(me.getValue()),
                                object_name:me.getText(),
                                category_name:me.getText(),
                                category_translated:me.getText()
                            };
                            webix.delay(() => {
                                this.getFreelancers(this.projectData.project.id)
                                .then(freelancers => {
                                    this.setFreelancers(freelancers);
                                    this.setPriceColumnHeader(selectedUom);
                                });
                            },10);
                        }
                    }
                }
            ],
        };
        return freelancersTableOptionsView;
    }

    getFreelancers(projectId: ImProjectId):Promise<IFreelancer[]> {
        // Getting filters values
        const filtersForm = (webix.$$("freelancersFilterForm") as webix.ui.form);
        const currentFilterValues = filtersForm.getValues();
        const sourceLanguageIds = currentFilterValues.source_language;
        const targetLanguageIds = currentFilterValues.target_language;
        const subjectAreaIds = currentFilterValues.subject_area;
        const skillBusinessSectorIds = currentFilterValues.skill;
        const skillTmToolIds = currentFilterValues.cat_tool;
        const skillRoleIds = currentFilterValues.role;
        const uomCombo = this.$$(`${this.idPrefix}UnitOfMeasurePriceFilter`) as webix.ui.combo;
        const uomId = Number(uomCombo.getValue());
        let params = {
            projectId,
            sourceLanguageIds,
            targetLanguageIds,
            subjectAreaIds,
            skillRoleIds,
            skillBusinessSectorIds,
            skillTmToolIds,
            uomId:uomId
        };
        if(uomId === 0) {
            delete params.uomId;
        }
        return WebixPortalAssignmentService.getFreelancersForProject(params)
        .then(freelancers => {
            this.allPossibleFreelancers = freelancers;
            this.lastUsedFilterValues = currentFilterValues;
            this.lastUsedPriceUomFilter = uomId;
            this.toggleFilterButton();
            return freelancers;
        })
        .catch(err => {
            console.log(err)
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
            return []
        });
    }

    areFilterValuesDifferent():boolean {
        // Main filters (multiple filtes in "Filter Options" section)
        const filtersForm = (webix.$$("freelancersFilterForm") as webix.ui.form);
        const currentFilterValues = filtersForm.getValues();
        const areMainFiltersEqual = JSON.stringify(this.lastUsedFilterValues) !== JSON.stringify(currentFilterValues);
        return areMainFiltersEqual
    }

    setFreelancers(freelancers:IFreelancer[]):void {
        const table = (webix.$$("selectFreelancersTable")) as webix.ui.datatable;
        table.clearAll();
        const sortedFreelancers = this.sortFreelancers(freelancers);
        table.define("data", sortedFreelancers);
        table.refresh();
        table.hideOverlay();
    }

    setPriceColumnHeader(uom?:ICategory):void {
        let feeTranslation = i18nHelper.getTranslation("Fee");
        if(uom) {
            feeTranslation = `${feeTranslation} (${uom.category_translated})`;
        }
        const table = (webix.$$("selectFreelancersTable")) as webix.ui.datatable;
        table.getColumnConfig("fee").header[0].text = feeTranslation;
        table.refreshColumns();
    }

    sortFreelancers(freelancers:IFreelancer[]):IFreelancer[] {
        const sortedFreelancers = freelancers.sort((fl1, fl2) => {
            if(fl1.sort_order === fl2.sort_order) {
                return fl1.rating < fl2.rating ? 1 : -1;
            } else {
                return fl1.sort_order > fl2.sort_order ? 1 : -1;
            }
        });
        return sortedFreelancers;
    }
    
    filterFreelancersByName():void {
        const textfield = (webix.$$("freelancersFilterByNameInput") as webix.ui.text);
        const freelancers = this.allPossibleFreelancers;
        const value = textfield.getValue();
        const lowercasedValue = value.toLowerCase();
        const newFreelancersArr = freelancers.filter(freelancer => {
            const lowercasedName = freelancer.freelancer.name.toLowerCase();
            if(lowercasedName.indexOf(lowercasedValue) > -1) {
                return true;
            }
        });
        this.setFreelancers(newFreelancersArr);
    }

    getWorkedWithColumns(workflowSteps:ITransWorkflowStep[]):{header:string, template():string}[] {
        const columns = [];
        workflowSteps.map(workflowStep => {
            let firstLetter = "";
            if(workflowStep.task_type.name[0]) {
                firstLetter = workflowStep.task_type.name[0].toUpperCase();
            }
            columns.push(
                {
                    header: i18nHelper.getTranslation(`worked_with_${firstLetter}C`), 
                    template:function(obj) {
                        let workedWithNumber = 0;
                        if(obj.worked_with) {
                            obj.worked_with.map(workedWith => {
                                if(workflowStep.task_type.id === workedWith.task_type.id) {
                                    workedWithNumber = workedWith.customer;
                                }
                            });
                        }
                        return workedWithNumber
                    }
                },
            )
        });
        workflowSteps.map(workflowStep => {
            let firstLetter = "";
            if(workflowStep.task_type.name[0]) {
                firstLetter = workflowStep.task_type.name[0].toUpperCase();
            }
            columns.push(
                {
                    header: i18nHelper.getTranslation(`worked_with_${firstLetter}G`), 
                    template:function(obj) {
                        let workedWithNumber = 0;
                        if(obj.worked_with) {
                            obj.worked_with.map(workedWith => {
                                if(workflowStep.task_type.id === workedWith.task_type.id) {
                                    workedWithNumber = workedWith.total;
                                }
                            });
                        }
                        return workedWithNumber;
                    }
                },
            )
        });
        return columns;
    }

    getSelectedFreelancers():IFreelancer[] {
        const table = ($$("selectFreelancersTable") as webix.ui.datatable);
        const freelancers = table.serialize();
        const selectedFreelancers = freelancers.filter(task => task.selected);
        return selectedFreelancers;
    }

    createAssignments(projectId:number):void {
        const searchbar = webix.$$("freelancersFilterByNameInput") as webix.ui.text;
        searchbar.setValue("");
        this.filterFreelancersByName();
        this.generateAssignmentModal = this.ui(GenerateAssignment) as GenerateAssignment;
        const selectedFreelancers = this.getSelectedFreelancers();
        let defaultUom = -1;
        const defaultUomCombo = this.$$(`${this.idPrefix}UnitOfMeasurePriceFilter`) as webix.ui.combo;
        if(defaultUomCombo) {
            defaultUom = Number(defaultUomCombo.getValue());
        }
        this.generateAssignmentModal.showModal(projectId, this.projectData, selectedFreelancers, this.projectWorkflowSteps, this.autoFocusId, defaultUom);
    }

    getProjectDefaultFilters(projectId:number):Promise<ITransProjectFilter> {
        const packageId:number = +this.autoFocusId;
        const params:{projectId:number, freelancePackageId?:number} = {
            projectId:projectId
        };
        if(this.autoFocusId && !isNaN(Number(this.autoFocusId))) {
            params.freelancePackageId = packageId;
        }
        return WebixPortalAssignmentService.getProjectDefaultFilters(params)
        .then(res => {
            return res;
        });
    }
    
    setFilterValues(defaultFilters: ITransProjectFilter):void {
        const filtersForm = (webix.$$("freelancersFilterForm") as webix.ui.form);
        // Source Language Filter
        const sourceLanguageFilter = (filtersForm.queryView({name:"source_language"}) as webix.ui.multiselect);
        const preparedDefaultSlFilters = this.webixHelpers.prepareDataForCombo(defaultFilters.source_language,"id", "name");
        sourceLanguageFilter.define("options", preparedDefaultSlFilters);
        if(defaultFilters.source_language[0]) {
            sourceLanguageFilter.setValue(defaultFilters.source_language[0].id.toString());
        }
        // Target Language filter
        const targetLanguageFilter = (filtersForm.queryView({name:"target_language"}) as webix.ui.multiselect);
        const preparedDefaultTlFilters = this.webixHelpers.prepareDataForCombo(defaultFilters.valid_target_language,"id", "name");
        targetLanguageFilter.define("options", preparedDefaultTlFilters);
        // If value exists at index 0 in 'target_language', then we know for sure it is the default value for TargetLang filter
        const commaSeperatedValidTargetLanguages = defaultFilters.target_language.map(validLanguage => { 
            return validLanguage.id 
        }).join(',');
        targetLanguageFilter.setValue(commaSeperatedValidTargetLanguages);
        // Subject Area filter
        if(defaultFilters.subject_area) {
            const subjectAreaFilter = (filtersForm.queryView({name:"subject_area"}) as webix.ui.multicombo);
            if(subjectAreaFilter) {
                subjectAreaFilter.setValue(defaultFilters.subject_area.id.toString());
            }
        }
        // Skill Business Sector filter
        if(defaultFilters?.skill_business_sector) {
            const skillBusinessSectorFilter = (filtersForm.queryView({name:"skill"}) as webix.ui.multicombo);
            skillBusinessSectorFilter.setValue(defaultFilters?.skill_business_sector[0].id.toString());
        }
        // Role filter
        if(defaultFilters?.skill_role) {
            const skillBusinessSectorFilter = (filtersForm.queryView({name:"role"}) as webix.ui.multicombo);
            skillBusinessSectorFilter.setValue(defaultFilters?.skill_role[0].id.toString());
        }        
        // Cat Tool filter
        /*
        if(defaultFilters.cat_tool) {
            const catToolFilter = (filtersForm.queryView({name:"cat_tool"}) as webix.ui.multicombo);
            catToolFilter.setValue(defaultFilters.cat_tool.id.toString());
        }   
        */
    }

    setDefaultPriceUom():Promise<void> {
        // Params used to get best 'UoM' can be either projectId and freelancerPackage
        // User 'projectId' as default value
        let bestUomParams:{projectId?:number, freelancePackageId?:number} = {
            projectId:this.projectData.project.id
        };
        // In case auto_focus_id exists, we use id as freelancePacakgeId
        if(this.autoFocusId && !isNaN(Number(this.autoFocusId))) {
            bestUomParams = {
                freelancePackageId:Number(this.autoFocusId)
            };
        }
        return WebixPortalAssignmentService.getBestUom(bestUomParams)
        .then(bestUom => {
            const uomCombo = this.$$(`${this.idPrefix}UnitOfMeasurePriceFilter`) as webix.ui.combo;
            uomCombo.blockEvent();
            uomCombo.setValueHere(bestUom.category_id.toString());
            uomCombo.unblockEvent();
        });
    }

    sortDatatableByPriceObj(firstVal:{fee:{rate:number | string, currency:string}[]}, secondVal:{fee:{rate:number | string, currency:string}[]}, direction:string):number {
        if(firstVal.fee === null || firstVal.fee[0]?.rate === "") {
            return -1;
        }
        if(secondVal.fee === null || secondVal.fee[0]?.rate === "") {
            return 1;
        }    
        let rate1 = -1;
        let rate2 = -1;
        if(firstVal.fee[0]?.rate || firstVal.fee[0].rate === "") {
            rate1 = Number(firstVal.fee[0]?.rate);
        }
        if(secondVal.fee[0]?.rate || secondVal.fee[0].rate === "") {
            rate2 = Number(secondVal.fee[0]?.rate);
        }
        return rate1 > rate2  ? 1 : (rate1 < rate2  ? -1 : 0);
    }
    
}
