import { CognovisNavigator } from "../../../sources/modules/cognovis-navigator/cognovis-navigator";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisRestObjectService, ICognovisObject } from "../../../sources/openapi";


export default class LinkedObjectsModal {
    
    static idPrefix = "lom";
    static objectId:number;

    static show(objectId:number, left:number, top:number):void {
        this.objectId = objectId;
        left = left - 2;
        top = top + 15;
        this.getLinkedObjects(objectId)
        .then(linkedObjects => {
            let popup:webix.ui.popup = webix.$$(`${this.idPrefix}LinkedObjectsModal`) as webix.ui.popup;
            if(!popup) {
                popup = webix.ui({
                    view:"popup",
                    id:`${this.idPrefix}LinkedObjectsModal`,
                    css:"linked-objects-popup transparent",
                    left:left,
                    width:170,
                    top:top,
                    body:{
                        view:"layout",
                        rows:[
                            {
                                template:"<span class='linked-objects-modal-triangle'>▵</span>",
                                borderless:true,
                                css:{
                                    "background-color":"transparent !important",
                                    "box-shadow":"none"
                                },
                                height:20
                            },
                            {
                                view:"layout",
                                css:{
                                    //"border":"2px solid red",
                                },
                                rows:[
                                    this.getLinkedObjectsTable()
                                ]
                            }
                            
                        ]
                    }
                }) as unknown as webix.ui.popup;
            } else {
                popup.setPosition(left,top);
            }
            popup.show();
            this.injectDataIntoTable(linkedObjects);
        });
    }

    static hide() {
        const popup:webix.ui.popup = webix.$$(`${this.idPrefix}LinkedObjectsModal`) as webix.ui.popup;
        popup.hide();
    }

    static getLinkedObjects(objectId:number):Promise<ICognovisObject[]> {
        return CognovisRestObjectService.getLinkedObjects({objectId:objectId})
        .then(linkedObjects => {
            return linkedObjects;
        });
    }

    static getLinkedObjectsTable():webix.ui.datatableConfig {
        const table = {
            view:"datatable",
            id:`${this.idPrefix}LinkedObjectsTable`,
            header:false,
            margin:3,
            padding:10,
            type:"space",
            autoheight:true,
            css:"cog-clickable-table",
            tooltip:true,
            columns:[
                {
                    id:"context_icon",
                    width:40,
                    tooltip:((obj:ICognovisObject) => {
                        return obj.type.name
                    }),
                    template:((obj:ICognovisObject) => {
                        let color = "";
                        if(obj.status_color) {
                            color = `color:${obj.status_color} !important;`;
                        }
                        if(obj.type_icon) {
                            return `<span style='${color}' class='cursor-pointer webix_icon ${obj.type_icon}'></span>`;
                        } else {
                            return ``;
                        }
                    })
                },
                {
                    id:"name",
                    fillspace:true,
                    tooltip:((obj:ICognovisObject) => {
                        return obj.status.name
                    }),
                    template:((obj:ICognovisObject) => {
                        let color = "";
                        if(obj.status_color) {
                            color = `color:${obj.status_color} !important;`
                        } 
                        if(obj.name) {
                            return `<span style='${color}'>${obj.name}</span>`;
                        } else {
                            return ``;
                        }
                    })
                }
            ],
            on: {
                onItemClick:(row) => {
                    const table = webix.$$(`${this.idPrefix}LinkedObjectsTable`) as webix.ui.datatable;
                    const item:ICognovisObject = table.getItem(row);
                    this.hide();
                    CognovisNavigator.goToObjectDetails(item.id, item.object_type);
                }
            }
        };
        return table;
    }

    static injectDataIntoTable(linkedObjects:ICognovisObject[]):void {
        const linkedObjectsTabe = webix.$$(`${this.idPrefix}LinkedObjectsTable`) as webix.ui.datatable;
        if(linkedObjectsTabe) {
            linkedObjectsTabe.clearAll();
            linkedObjectsTabe.define("data", linkedObjects);
            linkedObjectsTabe.refresh();
        }
    }

}