import { CognovisPleaseWaitWindow } from "../../../services/cognovis-please-wait-window";
import CognovisBasicModal from "../../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { container } from "tsyringe";
import { CognovisRestInvoiceService, IInvoiceItem, IntranetCostType, InvoiceItem } from "../../../../sources/openapi";
import { i18nHelper } from "../../../../sources/modules/i18n-helper/i18n-helper";
import { CognovisNavigator } from "../../../../sources/modules/cognovis-navigator/cognovis-navigator";


export default class CreateMultipleInvoiceModal extends CognovisBasicModal {

    idPrefix = "cmim";
    invoiceId:number;
    invoiceItems:IInvoiceItem[];
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    modalTitle:string;
    afterSubmitAction:() => void;

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(" ",800, 412);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        return mainLayout;
    }

    openModal(afterSubmitAction?:() => void):void {
        this.invoiceId = this.getParam("invoice_id", true);
        CognovisRestInvoiceService.getInvoiceItem({ 
            invoiceId: this.invoiceId,
            onlyNotInvoicedP: false
        })
        .then(invoiceItems => {
            this.invoiceItems = invoiceItems;
            if(afterSubmitAction) {
                this.afterSubmitAction = afterSubmitAction;
            }
            this.modalTitle = i18nHelper.getTranslation("Create multiple invoices");
            const modalContent = this.getContent(this.invoiceItems);
            const actionButtons = this.getActionButtons();
            this.setContent(modalContent, actionButtons, this.modalTitle);
            this.cognovisPleaseWaitWindow.hide();          
            const modal = (this.getRoot() as webix.ui.window);
            modal.show(); 
        });
    }

    getContent(invoiceItems:IInvoiceItem[]):webix.ui.layoutConfig {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const layout = {
            view:"scrollview",
            body:{
                padding:13,
                rows:[
                    {
                        view:"layout",
                        width:720,
                        localId:`${this.idPrefix}MultipleInvoicesModalContainer`,
                        rows:[
                            {
                                view:"layout",
                                cols:[
                                    {
                                        view:"checkbox",
                                        id:`${this.idPrefix}AlreadyInvoicedToggler`,
                                        labelWidth:0,
                                        value:true,
                                        labelRight: i18nHelper.getTranslation("Hide already invoiced items"),
                                        on: {
                                            onChange:() => {
                                                const checkbox = webix.$$(`${this.idPrefix}AlreadyInvoicedToggler`) as webix.ui.checkbox;
                                                const newValue = checkbox.getValue();
                                                const newValueBool = newValue ? true : false;
                                                this.toggleAlreadyInvoiced(newValueBool);
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                view:"form",
                                borderless:true,
                                id:`${this.idPrefix}MultipleInvoicesModalForm`,
                                elements:this.getLineItemsCheckboxes(invoiceItems)
                            },
                            {
                                borderless:true,
                                template:i18nHelper.getTranslation("No uninvoiced items"),
                                hidden:(this.invoiceItems.length > 0)
                            }
                        ]
                    }                   
                ]
            }
        };
        return layout
    }

    getLineItemsCheckboxes(invoiceItems:IInvoiceItem[]):webix.ui.layoutConfig {
        const rows = [];
        invoiceItems.map(invoiceItem => {
            const totalPrice = (invoiceItem.price_per_unit * invoiceItem.item_units).toFixed(2);
            const label = `${invoiceItem.item?.name}, ${invoiceItem.source_language?.name} => ${invoiceItem.target_language?.name}, 
            <b> ${totalPrice} ${invoiceItem?.currency}</b>`;
            rows.push(
                {
                    view:"checkbox",
                    name:invoiceItem.item.id,
                    labelWidth:0,
                    labelRight: label,
                    hidden:invoiceItem.item_already_invoiced_p,
                    alreadyInvoiced:invoiceItem.item_already_invoiced_p
                }
            );
        });
        return rows as webix.ui.layoutConfig
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            click:() => {
                                this.hide();
                            }
                        },  
                        {
                            view:"button",
                            localId:`${this.idPrefix}SaveButton`,
                            value:`${i18nHelper.getTranslation(`Save`)}`,
                            click:() => {
                                this.submit();
                            }
                        }
                    ] 
                };
        return buttons;
    }
    
    submit():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const form = webix.$$(`${this.idPrefix}MultipleInvoicesModalForm`) as webix.ui.form;
        const values = form.getValues();
        const ids = Object.entries(values).filter(entry => entry[1] === 1)
        .map(entry => Number(entry[0]));
        if(ids.length > 0) {
            const fullItems = this.invoiceItems.filter(invoiceItem => ids.includes(invoiceItem.item.id));
            CognovisRestInvoiceService.postInvoiceCopy({
                sourceInvoiceIds:[this.invoiceId],
                targetCostTypeId: IntranetCostType.CUSTOMER_INVOICE,
                noItemsP:true
            })
            .then(newInvoice => {
                return newInvoice
            })
            .then(newInvoice => {
                const promises = [];
                fullItems.map(invoiceItem => {
                    promises.push(CognovisRestInvoiceService.postInvoiceItem({
                        invoiceId:newInvoice.invoice.id,
                        requestBody:{
                            item_name:invoiceItem.item.name,
                            item_units:invoiceItem.item_units,
                            description:invoiceItem.description,
                            price_per_unit:invoiceItem.price_per_unit,
                            item_material_id:invoiceItem.item_material.id,
                            sort_order:invoiceItem.sort_order
                        }
                    }));
                });
                Promise.all(promises)
                .then(() => {
                    CognovisNavigator.navigateTo(`/main/invoice-detail.invoice-detail?invoice_id=${newInvoice.invoice.id}&project_id=${newInvoice.project.id}`);
                    setTimeout(() => {
                        this.cognovisPleaseWaitWindow.hide();
                        window.location.reload();
                    },100);
                });
            });
        } else {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title:i18nHelper.getTranslation("Error"),
                text: i18nHelper.getTranslation("Please select at least one item"),
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        }
    }

    toggleAlreadyInvoiced(newValue:boolean):void {
        const form = webix.$$(`${this.idPrefix}MultipleInvoicesModalForm`) as webix.ui.form;
        const checkboxes = form.getChildViews();
        checkboxes.forEach(checkbox => {
            console.log(checkbox.config);
            checkbox.show();
        });
        checkboxes.forEach(checkbox => {
            if(checkbox.config.alreadyInvoiced && newValue) {
                checkbox.hide();
            }
        });           
    }


}