import { IAssignment, IAssignmentQualityReport, ICategoryArray, IntranetFreelanceAssignmentStatus, WebixPortalAssignmentService, ICategory, CognovisRestService } from "../../../../openapi";
import RatingsLayout from "../ratings-layout/ratings-layout";
import CognovisBasicModal from "../../../../modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../../modules/i18n-helper/i18n-helper";
import { CognovisPleaseWaitWindow } from "../../../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";


export default class DeliveryRating extends CognovisBasicModal {

    idPrefix = "dr";
    currentAssignment:IAssignment;
    previousAssignmentId:number;
    isRatingPossible = false;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    ratingsLayout:RatingsLayout;
    callbackAction:() => void;
    qualityTypes:ICategoryArray;
    qualityLevels:ICategoryArray;

    init():void {
        this.ratingsLayout = container.resolve(RatingsLayout);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);  
    }

    config():webix.ui.windowConfig {
        const mainLayout = super.getMainLayout(i18nHelper.getTranslation(`Deliver_completed_job`), 1140, 860);
        return mainLayout;
    }

    openModal(assignment:IAssignment, callbackAction?:() => void):void {
        if(callbackAction) {
            this.callbackAction = callbackAction;
        }
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const actionButtons = this.getActionButtons();
        this.currentAssignment = assignment;  
        const translatedTitle = i18nHelper.getTranslation("Deliver_Completed_Job");
        WebixPortalAssignmentService.getPreviousAssignments({
            assignmentId:this.currentAssignment.assignment.id,
            assignmentStatusIds:[IntranetFreelanceAssignmentStatus.WORK_DELIVERED]
        })
        .then(previousAssignments => {
            let isRatingPossible = false;
            if(previousAssignments.length > 0) {
                isRatingPossible = true;
                this.previousAssignmentId = previousAssignments[0]?.assignment.id;
            }
            this.isRatingPossible = isRatingPossible;
            this.getContent(this.currentAssignment, this.isRatingPossible)
            .then(modalContent => {
                this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, translatedTitle);
                this.cognovisPleaseWaitWindow.hide();
            });
        })
    }

    openModalForRatingEdition(assignment:IAssignment, report:IAssignmentQualityReport, callbackAction?:() => void):void {
        if(callbackAction) {
            this.callbackAction = callbackAction;
        }
        const modal = (this.getRoot() as webix.ui.window);
        modal.show(); 
        const actionButtons = this.getEditionActionButtons(report.report.id);
        this.currentAssignment = assignment;  
        const translatedTitle = i18nHelper.getTranslation("Edit rating");
        this.getContentForEdition(this.currentAssignment)
        .then(modalContent => {
            this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, translatedTitle);
            this.checkExistingValues(report);
        });
    }


    closeModal():void {
        this.hide();
    }

    checkExistingValues(report:IAssignmentQualityReport):void {
        // Ratings
        const container = this.$$(`${this.idPrefix}PossibleRatingsContainer`) as webix.ui.layout;
        const checkboxes = container.getParentView().queryView({view:"checkbox"}, "all");
        checkboxes.map(checkbox => {
            checkbox.setValue(false);
        });
        report.rating.map(rating => {
            const concreteCheckbox = webix.$$(`${this.idPrefix}Checkbox-${rating.quality_type.id}-${rating.quality_level.id}`) as webix.ui.checkbox;
            if(concreteCheckbox) {
                concreteCheckbox.setValue(1);
            }
        });
        // Comment
        const commentContainer = webix.$$(`drCommentsCkEditor5`);
        (commentContainer as webix.ui.textarea).setValue(report.rating_comment);
    }

    getContentForEdition(assignment:IAssignment):Promise<webix.ui.layoutConfig> {
        return 	CognovisRestService.getImCategory({categoryType:"Intranet Translation Quality Type"})
        .then((categories) => {
            return  WebixPortalAssignmentService.getAssignmentQualityRatingTypes({
                assignmentIds:[assignment.assignment.id],
            })
            .then(res => {
                const modal = {
                        view:"scrollview",
                        localId:`${this.idPrefix}DeliveryRatingModal`,
                        body:{
                            padding:20,
                            rows:[
                                this.ratingsLayout.getRatingSectionsLayout(this.currentAssignment, res.quality_type, res.quality_level, categories),
                            ]
                        }
                }
                return modal;
            });
        })
    }

    getContent(assignment:IAssignment, isRatingPossible:boolean):Promise<webix.ui.layoutConfig> {
        
        const iHerebyConfirmTranslated = i18nHelper.getTranslation("I_hereby_confirm_that_" + assignment.assignment_type.id);
        if(isRatingPossible) {
            return 	CognovisRestService.getImCategory({categoryType:"Intranet Translation Quality Type"})
			.then((categories) => {
                return  WebixPortalAssignmentService.getAssignmentQualityRatingTypes({
                    assignmentIds:[assignment.assignment.id],
                })
                .then(res => {
                    this.qualityTypes = res.quality_type;
                    this.qualityLevels = res.quality_level;
                    const modal = {
                            view:"scrollview",
                            localId:`${this.idPrefix}DeliveryRatingModal`,
                            body:{
                                padding:13,
                                autoheight:true,
                                rows:[
                                    this.ratingsLayout.getRatingSectionsLayout(this.currentAssignment, res.quality_type, res.quality_level, categories),
                                    {
                                        view:"spacer",
                                        height:30,
                                    },
                                    {
                                        view:"checkbox",
                                        required:true,
                                        id:`${this.idPrefix}IHerebyConfirmCheckbox`,
                                        label:"",
                                        labelWidth:0,
                                        css:{
                                            "padding-left":"6px"
                                        }
                                    },
                                    {
                                        autowidth:true,
                                        padding:0,
                                        minWidth:480,
                                        borderless:true,
                                        autoheight:true,
                                        template:iHerebyConfirmTranslated
                                    }
                                ]
                            }
                    }
                    return modal;
                });
            });
        } else {
            return new Promise((resolve) => {
                const modal = {
                    view:"layout",
                    padding:20,
                    localId:`${this.idPrefix}DeliveryRatingModal`,
                    rows:[
                        {
                            view:"spacer",
                            height:30,
                        },
                        {
                            view:"checkbox",
                            required:true,
                            id:`${this.idPrefix}IHerebyConfirmCheckbox`,
                            label:"",
                            labelWidth:0,
                            css:{
                                "padding-left":"6px"
                            }
                        },
                        {
                            autowidth:true,
                            padding:0,
                            minWidth:480,
                            borderless:true,
                            autoheight:true,
                            template:iHerebyConfirmTranslated
                        }
                    ]
                }
                resolve(modal);
            });
        }
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Deliver_and_close`)}`, 
                            align: "left",
                            click:() => {
                                const iHerebyConfirmCheckbox = webix.$$(`${this.idPrefix}IHerebyConfirmCheckbox`) as webix.ui.checkbox;
                                if(iHerebyConfirmCheckbox.getValue()) {
                                    this.saveQualityReport(this.currentAssignment.assignment.id, this.previousAssignmentId);
                                } else {
                                    const requiredFieldsTranslated = i18nHelper.getTranslation("Missing_required_fields");
                                    const pleaseProvideRatingsAndMark = i18nHelper.getTranslation("Please_provide_ratings_and_confirm_commitment");
                                    webix.alert({
                                        title:requiredFieldsTranslated,
                                        text:pleaseProvideRatingsAndMark,
                                        modal:true,
                                        type:"alert-error"
                                    });
                                }
                            }
                        },
                    ] 
                };
        return buttons;
    }

    getEditionActionButtons(reportId:number):webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`, 
                            align: "left",
                            click:() => {
                                this.saveExistingQualityRatingReport(reportId);
                            }
                        },
                    ] 
                };
        return buttons;    
    }

    saveQualityReport(currentAssignmentId:number, previousAssignmentId:number):void {
        if(previousAssignmentId) {
            // Let's validate if user checked all boxes
            const container = this.$$(`${this.idPrefix}PossibleRatingsContainer`) as webix.ui.layout;
            const checkboxes = container.getParentView().queryView({view:"checkbox"}, "all");
            const checkedCheckboxes = checkboxes.filter(checkbox => checkbox.getValue() === 1);
            if(checkedCheckboxes.length === this.qualityTypes.length) {
                this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                const textareaComment = this.$$(`${this.idPrefix}CommentsCkEditor5`) as webix.ui.textarea;
                const comment = textareaComment.getValue();
                WebixPortalAssignmentService.postAssignmentQualityReport({
                    assignmentId:previousAssignmentId,
                    requestBody:{
                        rating_comment:comment
                    }
                })
                .then(newlyCreatedAssignmentQualityReport => {
                    const newlyCreatedReportId = newlyCreatedAssignmentQualityReport.report.id;
                    const container = this.$$(`${this.idPrefix}PossibleRatingsContainer`) as webix.ui.layout;
                    const checkboxes = container.getParentView().queryView({view:"checkbox"}, "all");
                    const checkedCheckboxes = [];
                    checkboxes.forEach((checkbox:webix.ui.checkbox) => {
                        if(checkbox.getValue() && checkbox.config["qualityType"]?.id && checkbox.config["qualityLevel"]?.id) {
                            checkedCheckboxes.push(checkbox.config);
                        } 
                    });
                    checkedCheckboxes.forEach((checkedCheckbox, i) => {
                        let lastOne = false;
                        if(i === (checkedCheckboxes.length -1)) {
                            lastOne = true;
                        }
                        this.saveQualityRatingIntoReport(newlyCreatedReportId, checkedCheckbox["qualityType"]?.id, checkedCheckbox["qualityLevel"]?.id, lastOne);
                    });
                });
            } else {
                const requiredFieldsTranslated = i18nHelper.getTranslation("Missing_required_fields");
                const pleaseProvideRatingsAndMark = i18nHelper.getTranslation("Please_provide_ratings_and_confirm_commitment");
                webix.alert({
                    title:requiredFieldsTranslated,
                    text:pleaseProvideRatingsAndMark,
                    modal:true,
                    type:"alert-error"
                });
            }
        } else {
            this.callbackAction();
        }

    }

    saveQualityRatingIntoReport(reportId:number, qualityTypeId:number, qualityLevelId:number, lastOne = false):void {
        WebixPortalAssignmentService.postAssignmentQualityRating({
            requestBody:{
                report_id:reportId,
                quality_type_id:qualityTypeId,
                quality_level_id:qualityLevelId
            }
        })
        .then(res => {
            if(res && lastOne) {
                if(this.callbackAction) {
                    this.callbackAction();
                }
                //window.location.reload();
            }
        })
        .catch(() => {
            if(lastOne) {
                if(this.callbackAction) {
                    this.callbackAction();
                }
                //window.location.reload();
            }
        });
    }

    saveExistingQualityRatingIntoReport(reportId:number, qualityTypeId:number, qualityLevelId:number, lastOne:boolean):void {
        WebixPortalAssignmentService.putAssignmentQualityRating({
            requestBody:{
                quality_level_id:qualityLevelId,
                quality_type_id:qualityTypeId,
                report_id:reportId
            }
        })
        .then(() => {
            if(lastOne) {
                window.location.reload();
            }
        });
    }


    saveExistingQualityRatingReport(reportId:number):void {
        const textareaComment = this.$$(`${this.idPrefix}CommentsCkEditor5`) as webix.ui.textarea;
        const comment = textareaComment.getValue();
        WebixPortalAssignmentService.putAssignmentQualityReport({
            reportId:reportId,
            requestBody:{
                rating_comment:comment
            }
        })
        .then(() => {
            const container = this.$$(`${this.idPrefix}PossibleRatingsContainer`) as webix.ui.layout;
            const checkboxes = container.getParentView().queryView({view:"checkbox"}, "all");
            const checkedCheckboxes = [];
            checkboxes.forEach((checkbox:webix.ui.checkbox) => {
                if(checkbox.getValue() && checkbox.config["qualityType"]?.id && checkbox.config["qualityLevel"]?.id) {
                    checkedCheckboxes.push(checkbox.config);
                } 
            });
            checkedCheckboxes.forEach((checkedCheckbox, i) => {
                let lastOne = false;
                if(i === (checkedCheckboxes.length -1)) {
                    lastOne = true;
                }
                this.saveExistingQualityRatingIntoReport(reportId, checkedCheckbox["qualityType"]?.id, checkedCheckbox["qualityLevel"]?.id, lastOne);
            });
        });
    }

}