import { CognovisRestService, IUserAbsence, ICategory, IDayOff, CognovisRestTimesheetService } from "../../../sources/openapi";
import { ITimesheetEntry } from "../../../sources/openapi";
import { CognovisRestAbsencesService } from "../../../sources/openapi";
import { IJetApp, JetView } from "webix-jet";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import { UserProfile } from "../cognovis-profile/profiles-types";
import { WebixHelpers } from "../webix-helpers/webix-helpers";
import { container } from "tsyringe";
import { DatetimeHelper } from "../datetime-helpers/datetime-helpers";
import DailyTimesheetEntriesModal from "./daily-timesheet-entries-modal";
import { i18nHelper } from "../i18n-helper/i18n-helper";
import CognovisBasicPortlet from "../cognovis-basic-portlet";



export default class CognovisWorkCalendar extends CognovisBasicPortlet {

    currentUser:{id:number, value:string, name:string};
    idPrefix = "cwc";
    currentUserTimesheetEntries:ITimesheetEntry[] = [];
    absenceTypes:ICategory[];
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    webixHelpers: WebixHelpers;
    currentlySelectedDate:Date;
    dailyTimesheetEntriesModal:DailyTimesheetEntriesModal;
    portletId:number;

    constructor(app:IJetApp, portletId:number, title?:string) {
        super(app, {});
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        const currentUserId = UserStateManager.getCurrentlyLoggedUser().id;
        const loggedUserData = webix.storage.session.get("logged_user_data");
        this.currentUser =   {
            id:currentUserId,
            value:`${loggedUserData.first_names} ${loggedUserData.last_name}`,
            name:`${loggedUserData.first_names} ${loggedUserData.last_name}`
        };
        this.webixHelpers = container.resolve(WebixHelpers);
        this.portletId = portletId;
        if(title) {
            this.title = title;
        } 
    }


    init():void {
        super.init();
        this.components = [
            {
                view:"layout",
                minHeight:400,
                cols:[
                    this.getCalendar()
                ]
            }
        ];
        this.insertComponents();
    }
    

    getCalendar():webix.ui.calendarConfig {
        const calendar = {
            view:"calendar",
            width:0,
            height:0,
            maxHeight:400,
            dayTemplate:(date, sec) => {
                const dayId = this.getCalendarDayDivId(date.getDate(), (date.getMonth()+1), date.getFullYear());
                let isThatDayInTheFuture = false;
                const dayInCalnedarTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                const todayTime = new Date();
                if(dayInCalnedarTime.getTime() >= todayTime.getTime()) {
                    isThatDayInTheFuture = true;
                }
                const fullDate = this.getFullDate(date.getDate(), (date.getMonth()+1), date.getFullYear());
                const html =  `<div id='${dayId}' data-cog-full-date='${fullDate}' data-cog-is-date-in-future='${isThatDayInTheFuture}' webix_tooltip='0' data-acch='0' data-dayofweek='${date.getDay()}' class='day cog-calendar-day-marker'>${date.getDate()}</div>`;
                return html;
            },
            on: {
                onAfterRender:() => {
                    const datesFromCalendar = this.getCurrentDatesFromCalendar();
                    this.markDaysInCalendar(datesFromCalendar[0], datesFromCalendar[1]);
                },
                onAfterMonthChange:(values) => {
                    const datesFromCalendar = this.getCurrentDatesFromCalendar();
                    this.markDaysInCalendar(datesFromCalendar[0], datesFromCalendar[1]);
                },
                onBeforeDateSelect:(date) => {
                    this.currentlySelectedDate = date;
                    this.openDailyTimesheetEntriesModal(date)
                    return false
                }
            }
        };
        return calendar
    }

    getCalendarDayDivId(day:number, month:number, year:number):string {
        let correctedDay = `${day}`;
        if(day <= 9) {
            correctedDay = `0${day}`
        }
        let correctedMonth = `${month}`;
        if(month <= 9) {
            correctedMonth = `0${month}`;
        }
        const dayId = `${this.idPrefix}-day-${correctedDay}-${correctedMonth}-${year}`;
        return dayId;
    }

    getFullDate(day:number, month:number, year:number):string {
        let correctedDay = `${day}`;
        if(day <= 9) {
            correctedDay = `0${day}`
        }
        let correctedMonth = `${month}`;
        if(month <=9) {
            correctedMonth = `0${month}`;
        }
        const dayId = `${year}-${correctedMonth}-${correctedDay}`;
        return dayId;
    }

    getCurrentDatesFromCalendar():[string, string] {
        const days = document.querySelectorAll(".cog-calendar-day-marker");
        const startingDay = days[0];
        const extractedStartingDay = startingDay["dataset"]["cogFullDate"];
        const endingDate = days[days.length-1];
        const extractedEndingDay = endingDate["dataset"]["cogFullDate"];
        return [extractedStartingDay, extractedEndingDay]
    }

    markDaysInCalendar(startindDate:string, endingDate:string):void {
        if(this.currentUserTimesheetEntries.length > 0) {
            setTimeout(() => {
                this.addClassesToCalendar(this.currentUserTimesheetEntries, [], []);
            },100);
        } else {
            this.getTimesheetEntries(this.currentUser.id, startindDate, endingDate)
            .then(entries => {
                CognovisRestAbsencesService.getDaysOff({
                    startDate:startindDate,
                    endDate:endingDate
                })
                .then(daysOff => {
                    CognovisRestAbsencesService.getUserAbsence({ownerId:this.currentUser.id, groupId:UserProfile.EMPLOYEE})
                    .then(absences => {
                        CognovisRestService.getImCategory({categoryType:"Intranet Absence Type"})
                        .then(absenceTypes => {
                            this.absenceTypes = absenceTypes;
                            this.addClassesToCalendar(entries, daysOff, absences);
                        });
                    });
                });
            });
        }
    }
    
    addClassesToCalendar(entries:ITimesheetEntry[], daysOff:IDayOff[], absences:IUserAbsence[]):void {
        const days = document.querySelectorAll(".cog-calendar-day-marker");
        days.forEach(dayDiv => {
            webix.TooltipControl.addTooltip(dayDiv.id as string, this.webixHelpers.convertHoursDecimalToDuration("0"));
            if(dayDiv["dataset"]["dayofweek"] !== "0" && dayDiv["dataset"]["dayofweek"] !== "6" && dayDiv["dataset"]["cogIsDateInFuture"] !== "true") {
                dayDiv.classList.add('noentries');
            }
        });
        const myFormat = webix.Date.dateToStr("%d-%m-%Y", false);
        entries.map((entry,index) => {
            const parsedDate = myFormat(new Date(entry.day));
            const dayId = `${this.idPrefix}-day-${parsedDate}`;
            const node = document.getElementById(dayId);
            if(node) {
                webix.html.removeCss(node, "noentries");
                // acch stands for accumulated hours
                let accumulatedHours = entry.hours;
                // Fix the line below
                if(node.dataset["acch"]) {
                    accumulatedHours = accumulatedHours + parseFloat(node.dataset["acch"]);
                }
                node.dataset["acch"] = accumulatedHours.toString();
                const convertedHours = this.webixHelpers.convertHoursDecimalToDuration(accumulatedHours.toString());
                webix.TooltipControl.addTooltip(node.id as string, convertedHours);
                if(accumulatedHours <= 6) {
                    webix.html.addCss(node, "notalotofentries");
                }
            }
        });
        daysOff.map(dayOff => {
            const parsedDate = myFormat(new Date(dayOff.day));
            const dayId = `${this.idPrefix}-day-${parsedDate}`;
            const node = document.getElementById(dayId);
            if(node) {
                node.style.backgroundColor = `#${dayOff.color}`;
                webix.TooltipControl.addTooltip(node.id as string, dayOff.absence_type.name);
            }
        });
        absences.map(absence => {
            const absenceStartDate = new Date(absence.start_date);
            const absenceEndDate = new Date(absence.end_date);
            let loopDate = absenceStartDate;
            while(loopDate <= absenceEndDate) {
                const parsedDate = myFormat(loopDate);
                const dayId = `${this.idPrefix}-day-${parsedDate}`;
                const node = document.getElementById(dayId);
                if(node) {
                    //webix.html.addCss(node, "absence");
                    const absenceTypeColor = this.absenceTypes.find(cat => cat.category_id === absence.absence_type.id).aux_string2;
                    if(absenceTypeColor) {
                        node.style.backgroundColor = `#${absenceTypeColor}`;
                    }
                    webix.TooltipControl.addTooltip(node.id as string, absence.absence.name);
                }
                const newLoopDate = loopDate.setDate(loopDate.getDate() + 1);
                loopDate = new Date(newLoopDate);
            }
        });
        // Lines below are fix for accumulated hours duplicating on month change
        days.forEach(dayDiv => {
            dayDiv.setAttribute("data-acch", "0");
        });
    }

    getTimesheetEntries(userId:number, startDate:string, endDate:string):Promise<ITimesheetEntry[]> {
        return CognovisRestTimesheetService.getTimesheetEntry({
            userId:userId,
            startDate:startDate,
            endDate:endDate
        })
        .then(entries => {
            let withHoursDecimal = entries.map(entry => {
                entry["day"] = DatetimeHelper.parseDateForDatepicker(entry.day);
                entry["duration"] = this.webixHelpers.convertHoursDecimalToDuration(entry.hours.toString());
                return entry
            });
            return withHoursDecimal
        });
    }

    openDailyTimesheetEntriesModal(selectedDate:Date):void{
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        this.getTimesheetEntries(this.currentUser.id, this.getCurrentDatesFromCalendar()[0], this.getCurrentDatesFromCalendar()[1])
        .then(timesheetEntries => {
            if(!this.dailyTimesheetEntriesModal) {
                this.dailyTimesheetEntriesModal = this.ui(DailyTimesheetEntriesModal) as DailyTimesheetEntriesModal;
            }
            this.cognovisPleaseWaitWindow.hide();
            this.dailyTimesheetEntriesModal.openModal(selectedDate, timesheetEntries, () => {
                const datesFromCalendar = this.getCurrentDatesFromCalendar();
                this.markDaysInCalendar(datesFromCalendar[0], datesFromCalendar[1]);
            });
        });
    }
}

