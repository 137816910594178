
import { CognovisRestCompanyService, IntranetCompanyStatus, IntranetCompanyType, ITransProject, WebixPortalTranslationService } from "../../../sources/openapi";
import CognovisBasicModal from "../../../sources/modules/cognovis-basic-modal/cognovis-basic-modal";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { container } from "tsyringe";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";


export default class DuplicateProjectModal extends CognovisBasicModal {

    idPrefix = "dpm";
    currentProject:ITransProject;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;

    config():webix.ui.windowConfig {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        const mainLayout = super.getMainLayout(" ",800,640);
        return mainLayout;
    }

    openModal(currentProject:ITransProject):void {
        this.currentProject = currentProject;
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        // First we will get possible customers and default contact
        CognovisRestCompanyService.getCompanies({
            companyStatusIds: [IntranetCompanyStatus.ACTIVE],
            companyTypeIds: [IntranetCompanyType.CUSTOMER]
        })
        .then(companyInfo => {
            this.cognovisPleaseWaitWindow.hide();
            const modal = (this.getRoot() as webix.ui.window);
            const customerOptionsForCombo = companyInfo.map(company => ({id:company.company.id, value:company.company.name}))     
            const modalContent = this.getContent(customerOptionsForCombo);
            const translatedTitle = i18nHelper.getTranslation("Duplicate_project");
            const modalTitle = `${currentProject.project_nr}: ${translatedTitle}`;
            const actionButtons = this.getActionButtons();
            this.setContent(modalContent, actionButtons as webix.ui.layoutConfig, modalTitle); 
            modal.show(); 
            const customerCombo = this.$$(`${this.idPrefix}Customer`) as webix.ui.combo;
            customerCombo.setValue(this.currentProject.company.id.toString());
            
        });
    }

    closeModal():void {
        this.hide();
    }

    getContent(possibleCustomers:{id:Number, value:string}[]):webix.ui.layoutConfig {
        const layout = {
            view:"layout",
            css:{
                "margin-left":"13px !important"
            },
            rows:[
                {
                    view:"label",
                    label:i18nHelper.getTranslation("Select_elements_to_duplicate")
                },
                this.getForm(possibleCustomers)
            ]
        };
        return layout;
    }

    getForm(possibleCustomers:{id:Number, value:string}[]):webix.ui.layoutConfig {
        const form = {
            view:"form",
            localId:`${this.idPrefix}DuplicateProjectForm`,
            borderless:true,
            elements:[
                { 
                    view:"text", 
                    name:"project_name",
                    label:i18nHelper.getTranslation("project_name"),
                    labelWidth:220,
                    on: {
                        onAfterRender:function() {
                            webix.delay(() => {
                                this.focus();
                            },1);
                        }
                    }
                },
                {
                    view:"combo",
                    name:"customer",
                    localId:`${this.idPrefix}Customer`,
                    label:i18nHelper.getTranslation("customer"),
                    labelWidth:220,
                    //value:this.currentProject.company.id,
                    options:possibleCustomers,
                    on: {
                        onChange: (companyId) => {
                            CognovisRestCompanyService.getCompanyContacts({
                                companyId: companyId
                            })
                            .then((possibleContacts) => {
                                const possibleContactsForCombo = possibleContacts.map(contact => ({id:contact.contact.id, value:contact.contact.name}));
                                const customerContactCombo = this.$$(`${this.idPrefix}CustomerContact`) as webix.ui.combo;
                                customerContactCombo.define("options", possibleContactsForCombo);
                                customerContactCombo.setValue(this.currentProject.company_contact.id.toString());
                            });
                        },
                    } 
                },
                {
                    view:"combo",
                    name:"customer_contact",
                    localId:`${this.idPrefix}CustomerContact`,
                    label:i18nHelper.getTranslation("customer_contact"),
                    labelWidth:220
                },
                {
                    view:"layout",
                    cols:[
                        {
                            view:"layout",
                            rows:[
                                {
                                    view:"checkbox",
                                    name:"duplicate_trans_tasks",
                                    label:i18nHelper.getTranslation("Trans_tasks"),
                                    labelWidth:220
                                },
                                {
                                    view:"checkbox",
                                    name:"duplicate_project_time_tracking_tasks",
                                    label:i18nHelper.getTranslation("Time_tracking_tasks"),
                                    labelWidth:220
                                },
                                {
                                    view:"checkbox",
                                    name:"duplicate_project_quotes",
                                    label:i18nHelper.getTranslation("Quotes"),
                                    labelWidth:220
                                },
                                {
                                    view:"checkbox",
                                    name:"duplicate_project_batches",
                                    label:i18nHelper.getTranslation("Batches"),
                                    labelWidth:220
                                }
                            ]
                        },
                        {
                            view:"layout",
                            rows:[
                                {
                                    view:"checkbox",
                                    name:"duplicate_project_members",
                                    label:i18nHelper.getTranslation("Project_members"),
                                    labelWidth:220
                                },
                                {
                                    view:"checkbox",
                                    name:"duplicate_project_notes",
                                    label:i18nHelper.getTranslation("Project_notes"),
                                    labelWidth:220
                                },
                                {
                                    view:"checkbox",
                                    name:"duplicate_files",
                                    label:i18nHelper.getTranslation("Files"),
                                    labelWidth:220
                                }
                            ]
                        }
                    ]
                }
            ],
            rules: {
                project_name: webix.rules.isNotEmpty
            },
        }
        const layout = {
            view:"layout",
            padding:13,
            rows:[
                form
            ]
        }
        return layout
    }

    getActionButtons():webix.ui.layoutConfig {
        const buttons = 
                {
                    view:"layout",
                    padding:0,
                    cols:[
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Close`)}`,
                            align: "right",
                            click:() => {
                                this.closeModal();
                            }
                        },  
                        { 
                            view: "button", 
                            value: `${i18nHelper.getTranslation(`Save`)}`,
                            align: "right",
                            click:() => {
                                this.submitForm();
                            }
                        }
                    ] 
                };
        return buttons;
    }

    submitForm():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        const form = this.$$(`${this.idPrefix}DuplicateProjectForm`) as webix.ui.form;
        if(form.validate()) {
            const values = form.getValues();
            WebixPortalTranslationService.postCloneTransProject(
                {
                    projectId:this.currentProject.project.id,
                    requestBody:{
                        project_name:values.project_name,
                        company_id:values.customer,
                        company_contact_id:values.customer_contact,
                        timesheet_tasks:values.duplicate_project_time_tracking_tasks,
                        quotes: values.duplicate_project_quotes,
                        project_members:values.duplicate_project_members,
                        project_notes:values.duplicate_project_notes,
                        trans_tasks:values.duplicate_trans_tasks
                    }
                }
            )
            .then(newProject => {
                this.cognovisPleaseWaitWindow.hide();
                window.location.href = `/#!/main/project-info.project-info?project_id=${newProject.project.id}`; 
                window.location.reload();
            })
            .catch(err => {
                this.cognovisPleaseWaitWindow.hide();
                webix.alert({
                    title: err.message,
                    text: err.body.message,
                    type: "alert-error",
                    width: 500,
                    css:"cog-remove-tasks-modal"
                });
            })
        }
    }

}