import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import { container } from "tsyringe";
import {config} from "../../config";
import ProjectChatBackend from "./project-chat-backend";
import * as chat from "@xbs/chat";
import { UserStateManager } from "../../../sources/services/user-state-manager";
import { WebixCallback } from "@xbs/webix-pro";
import { WebixPortalTranslationService } from "../../../sources/openapi/services/WebixPortalTranslationService";
import { ITransProject, WebixPortalProjectService } from "../../../sources/openapi";
import ProjectDetails from "views/project-details";

// Project chat
export default class ProjectChat extends ProjectDetails {

    idPrefix = "cc";
    chatUrl = config.chatUrl;
    projectId:number;
    project:ITransProject;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    chat:webix.ui.chat;
    token:string;
    
    config():webix.ui.layoutConfig {
        const createChat = this.getParam("create_chat", false);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        // First we must login to chat
        return {
            view:"layout",
            localId:`${this.idPrefix}ChatContainer`,
            rows:[]
        } as unknown as webix.ui.layoutConfig
    }

    getChatUI(token:string, project?:ITransProject):webix.ui.chatConfig {
        this.token = token;
        return  {
            view:"chat",
            id:`${this.idPrefix}Chat`,
            token: token,
            url:`${this.chatUrl}/api/v1`,
            currentProject:project,
            override: new Map([[chat.services.Backend, ProjectChatBackend]]),

        } as unknown as webix.ui.chatConfig
    }

    init(view):void {
        this.projectId = this.getParam("project_id", false);
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
        WebixPortalTranslationService.getTransProjects({projectId:this.projectId})
        .then(projects => {
            if(projects[0]) {
                this.loginToChat(fetchedToken => {
                    const chatUI = this.getChatUI(fetchedToken, projects[0]);
                    const container = this.$$(`${this.idPrefix}ChatContainer`) as webix.ui.layout;
                    container.define("rows", [chatUI]);
                    container.reconstruct();
                    this.chat = (webix.$$(`${this.idPrefix}Chat`) as webix.ui.chat);
                    this.hideNotNeededElements();
                });
            }
        });  
    }

    getChatStatus():void {
        webix.ajax().get(`${this.chatUrl}/api/status`)
        .then(status => {
            return status.json()
        })
        .then(stat => {
            console.log(stat.Hub.Channels.chats.Subscribed)
        });
    }

    loginToChat(callback:WebixCallback):void {
        const currentUserId = UserStateManager.getCurrentlyLoggedUser().id;
        webix.ajax().headers({
            "Content-type":"application/json"
        })
        .post(
            `${this.chatUrl}/cog-login`,
            {
                id:currentUserId,
                email:`${UserStateManager.currentlyLoggedUser.userData.user.name}@`,
                avatar:UserStateManager.currentlyLoggedUser.userData.portrait_url,
                name:UserStateManager.currentlyLoggedUser.userData.user.name
            },
            (res) => {
                callback(res);
            }
        )
        .catch(err => {
            this.cognovisPleaseWaitWindow.hide();
            webix.alert({
                title: i18nHelper.getTranslation("Error"),
                text:i18nHelper.getTranslation("Could not connect co chat"),
                minWidth:500,
                type: "alert-error"
            });
        });
    }

    hideNotNeededElements():void {
        const elementsToHide = ["webix_chat_add_button", "webix_chat_tabbar", "webix_icon chi-account-plus"]
        setTimeout(() => {
            const chat = webix.$$(`${this.idPrefix}Chat`);
            elementsToHide.map(element => {
                const el = chat.queryView({css:element});
                if(el) {
                    el.hide();
                }
            });
            this.cognovisPleaseWaitWindow.hide();
        },100);
    }

}