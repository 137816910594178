import { i18nHelper } from "../../../sources/modules/i18n-helper/i18n-helper";
import BaseView from "views/baseview";
import { CognovisRestCompanyService, CognovisRestService, ICompanyContact, IUser, IWebixPortlet, WebixPortalPortletService } from "../../../sources/openapi";
import { CognovisPleaseWaitWindow } from "../../../sources/services/cognovis-please-wait-window";
import { container } from "tsyringe";
import { WebixHelpers } from "../../../sources/modules/webix-helpers/webix-helpers";
import UserDataModal from "views/modals/user-data-modal";
import UserProjects from "./user-projects";
import SkillsPortlet from "./skills-portlet";
import MessagesPortlet from "views/portlets/messages-portlet";
import ObjectNotesPortlet from "views/portlets/object-notes-portlet";
import MainTranslatorsPortlet from "./main-translators-portlet";
import { JetView } from "webix-jet";
import DynUserBasicInfoPortlet from "./dyn-user-basic-info-portlet";
import Sidebar from "views/sidebar";


export default class UserDetails extends BaseView {
    
    idPrefix = "ud";
    companyId:number;
    currentUserId:number;
    webixHelpers:WebixHelpers;
    currentUser:IUser;
    currentContact:ICompanyContact;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    userDataModal:UserDataModal;

    config():webix.ui.layoutConfig {
        this.currentUserId = this.getParam("user_id", true);
        this.companyId = this.getParam("company_id", true);
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);
        this.webixHelpers = container.resolve(WebixHelpers);
        const layout = {
            view:"scrollview",
            gravity:1,
            css: "cog-content",
            autowidth:true,
            id:`${this.idPrefix}MainContainer`,
            body:{
                padding:13,
                id:`${this.idPrefix}MainContainerBody`,
                rows:[]
            }
        } as webix.ui.layoutConfig;
        return layout
    }

    init():void {
        this.buildLayout();
    }

    urlChange():void {
        const newUserId = this.getParam("user_id", true);
        if(newUserId !== this.currentUserId) {
            this.currentUserId = newUserId;
            this.buildLayout();
        }
    }

    buildLayout():void {
        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")})
        this.getUserData(this.currentUserId)
        .then(res => {
            this.currentUser = res.user;
            this.currentContact = res.companyContact;
            const mainContainer = webix.$$(`${this.idPrefix}MainContainerBody`) as webix.ui.layout;
            mainContainer.define("rows", []);
            mainContainer.reconstruct();
            WebixPortalPortletService.getWebixPortlets({objectId:this.currentUserId, pageUrl:"user-details"})
            .then(portlets => {
                const portletsArr = this.createPortlets(portlets);
                const rows = this.figureOutProperLayout(portletsArr);
                rows.map(row => {
                    mainContainer.addView(row);
                });
                if(this.currentUser.company) {
                    const sidebar = webix.$$("sidebarMainSidebar") as webix.ui.sidebar;
                    CognovisRestCompanyService.getCompanies({
                        companyId:this.currentUser.company.id
                    })
                    .then(companies => {
                        if(companies[0]) {
                            (sidebar.$scope as Sidebar).openCompany(companies[0], true, this.currentUser);
                            //sidebar.$scope.updateUsersLabel(this.currentUser.user.id, this.currentUser.user.name);
                            this.cognovisPleaseWaitWindow.hide();
                        }
                    });
                } else {
                    this.cognovisPleaseWaitWindow.hide();
                }
            })
        });
    }

    createPortlets(components:IWebixPortlet[]):webix.ui.layoutConfig[] {
        const views:webix.ui.layoutConfig[] = [];
        const sortedComponents = components.sort((a,b) => (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0))
        sortedComponents.map(webixPortlet => {
            let portlet;
            switch(webixPortlet.portlet_type.name) {
                case "UserBasicInfoPortlet":
                    portlet = new DynUserBasicInfoPortlet(this.app, this.currentUserId, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`), webixPortlet.portlet_id);
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "SkillsPortlet":
                    portlet = new SkillsPortlet(this.app, this.currentUserId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "UserProjects":
                    portlet = new UserProjects(this.app, this.currentUserId, webixPortlet.portlet_id,  i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "MessagesPortlet":
                    portlet = new MessagesPortlet(this.app, {partyId:this.currentUserId}, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "UserNotesPortlet":
                    portlet = new ObjectNotesPortlet(this.app, this.currentUserId, webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
                case "MainTranslatorsPortlet":
                    portlet = new MainTranslatorsPortlet(this.app,  this.currentUserId, "", "user", webixPortlet.portlet_id, i18nHelper.getTranslation(`${webixPortlet.portlet_name}`));
                    portlet = this.convertJetViewToLayout(portlet, webixPortlet.portlet_id, webixPortlet.portlet_name, webixPortlet.location);
                    break;
            }
            views.push(portlet);
        });
        
        return views;
    }

    convertJetViewToLayout(jetview:JetView, portletId:number, name:string, position:string):webix.ui.layoutConfig {
        return {
            view:"layout",
            name:name,
            componentPosition:position,
            portletId:portletId,
            rows:[
                jetview
            ]
        } as webix.ui.layoutConfig
    }

    figureOutProperLayout(portlets:webix.ui.layoutConfig[]):webix.ui.layoutConfig[] {
        const layoutArr:webix.ui.layoutConfig [] = [];
        const totalRows = Math.ceil(portlets.length / 2);
        const alreadyUsedLeftPortlets = [];
        const alreadyUsedRightPortlets = [];
        const leftPortlets = portlets.filter(portlet => portlet["componentPosition"] === "left");
        const rightPortlets = portlets.filter(portlet => portlet["componentPosition"] === "right");
        for(let index = 1; index <= totalRows; index ++) {
            let leftPortlet = {};
            let rightPortlet = {};
            // Find next unused left portlet
            leftPortlets.reverse().map(lPortlet => {
                if(alreadyUsedLeftPortlets.indexOf(lPortlet["portletId"]) === -1) {
                    leftPortlet = lPortlet;
                }
            });
            // Find next unused right portlet
            rightPortlets.reverse().map(rPortlet => {
                if(alreadyUsedRightPortlets.indexOf(rPortlet["portletId"]) === -1) {
                    rightPortlet = rPortlet;
                }
            });
            const layout = {
                view:"layout",
                height:0,
                rows:[
                    {
                        view:"spacer",
                        height:13
                    },
                    {
                        view:"layout",
                        cols:[
                            leftPortlet,
                            {
                                view:"spacer",
                                width:13
                            },
                            rightPortlet
                        ]
                    }
                ]
            } as webix.ui.layoutConfig
            if(leftPortlet) {
                alreadyUsedLeftPortlets.push(leftPortlet["portletId"]);
            }
            if(rightPortlet) {
                alreadyUsedRightPortlets.push(rightPortlet["portletId"]);
            }
            layoutArr.push(layout);
        };
        return layoutArr
    }

    getUserData(userId:number):Promise<{user:IUser, companyContact:ICompanyContact}> {
        return CognovisRestService.getUser({
            userIds:[this.currentUserId]
        })
        .then(user => {
            this.currentUser = user[0];
            return CognovisRestCompanyService.getCompanyContacts({
                contactIds:[user[0].user.id]
            })
            .then(companyContact => {
                this.currentContact = companyContact[0];
                return {user:user[0], companyContact:companyContact[0]}
            });
        });
    }

}