import { CognovisRestService, CognovisRestSystemService, OpenAPI } from "../../sources/openapi";
import { JetView } from "webix-jet";

import { IBaseView } from "webix-jet/dist/types/interfaces";
import Session from "../models/session";
import { config } from "../../sources/config";


export default class ForgotPasswordView extends JetView {

    idPrefix = "pu";
    token:string;

    config():webix.ui.layoutConfig {
        this.token = this.getParam("t", true);
        if(this.token && this.token !== "undefined") {
            OpenAPI.TOKEN = this.token;
            return this.getForgotPasswordFormLayout();
        } else {
            return this.getNoTokenInfoLayout();
        }
    }

    getNoTokenInfoLayout():webix.ui.layoutConfig {
        return {
            view:"layout",
            id:`${this.idPrefix}PasswordUpdateContainer`,
            rows:[
                {
                    view:"scrollview",
                    css:"login-container",
                    body:{
                        cols:[
                            {
                                view:"spacer",
                                gravity:0.5
                            },
                            {
                                view:"layout",
                                rows:[
                                    {
                                        view:"spacer",
                                        height:40
                                    },
                                    {
                                        view:"layout",
                                        height:40,
                                        rows:[
                                           {
                                               template:"No token available",
                                               css:"center"
                                           }
                                        ]
                                    },
                                    {
                                        view:"spacer",
                                        height:40
                                    },
                                    {
                                        template:"<img src='"+ config.logoUrl + "' alt='' />",
                                        borderless:true,
                                        css:"login-form-container-logo",
                                        height:80
                                    },
                                    {
                                        view:"spacer",
                                        height:40
                                    }
                                ]
                            },
                            {
                                view:"spacer",
                                gravity:0.5
                            }
                        ]
                    }
                }
            ]
        }
    }

    getForgotPasswordFormLayout():webix.ui.layoutConfig {
        return {
            view:"layout",
            id:`${this.idPrefix}PasswordUpdateContainer`,
            rows:[
                {
                    view:"scrollview",
                    css:"login-container",
                    body:{
                        cols:[
                            {
                                view:"spacer",
                                gravity:0.5
                            },
                            {
                                view:"layout",
                                rows:[
                                    {
                                        view:"spacer",
                                        height:40
                                    },
                                    {
                                        view:"layout",
                                        height:500,
                                        rows:[
                                            this.getUpdatePasswordFormLayout()
                                        ]
                                    },
                                    {
                                        view:"spacer",
                                        height:40
                                    },
                                    {
                                        template:"<img src='"+ config.logoUrl + "' alt='' />",
                                        borderless:true,
                                        css:"login-form-container-logo slide-in-blurred-bottom",
                                        height:80
                                    },
                                    {
                                        view:"spacer",
                                        height:40
                                    }
                                ]
                            },
                            {
                                view:"spacer",
                                gravity:0.5
                            }
                        ]
                    }
                }
            ]
        }
    }

    init(view: IBaseView): void {
        // Check for session
        const isUserLogged = Session.status();
        isUserLogged
        .then((userLogged) => {
            if (userLogged) {
                window.location.replace("/#!/main/project-overview.projects");
            } 
        })
        .catch(err => {
            this.focusOnField("password");
            webix.UIManager.addHotKey("enter", () => {
                this.attemptUpdatePassword();
            });
        });
    }

    getUpdatePasswordFormLayout(): webix.ui.formConfig {
        return {
            view:"layout",
            id:`${this.idPrefix}UpdatePassword`,
            minWidth:360,
            height:0,
            paddingY:20,
            css:"login-form-container slide-in-blurred-top",
            rows:[
                {
                    template:`<h1>New Password</h1>`,
                    borderless:true,
                    css:"login-form-container-h1 center",
                    height:60
                },
                {
                    view:"form",
                    borderless:true,
                    padding:40,
                    elements:[
                        this.getLabelInputTemplate("password", "Password", "far fa-key", "Type your new password", "password"),
                        this.getLabelInputTemplate("password_confirm", "Password Confirm", "far fa-key", "Type your new password again", "password"),
                        {
                            view:"button",
                            css:"cog-button-big",
                            inputWidth:200,
                            inputHeight:0,
                            align:"center",
                            value:"Submit",
                            click:() => {
                                this.attemptUpdatePassword();
                            }
                        }
                    ],
                    rules: {
                        password: webix.rules.isNotEmpty,
                        password_confirm: webix.rules.isNotEmpty,
                    }
                }
            ]
        }
    }

    getLabelInputTemplate(name:string, label:string, icon:string, placeholder:string, type = ""):webix.ui.layoutConfig {
        return {
            view:"layout",
            height:100,
            rows:[
                {
                    view:"label",
                    css:"login-container-input-label",
                    label:label
                },
                {
                    view:"layout",
                    css:"login-container-input-box",
                    cols:[
                        {
                            template:`<i style='font-size:18px;' class="${icon}"></i>`,
                            borderless:true,
                            width:40
                        },
                        {
                            view:"text",
                            type:type,
                            placeholder:placeholder,
                            css:"login-container-input",
                            name:name
                        }
                    ]
                },
                {
                    view:"spacer",
                    height:10
                }
            ]
        }
    }

    attemptUpdatePassword(): void {
        const updatePasswordForm = this.getForm();
        const password = updatePasswordForm.getValues().password;
        const passwordConfirm = updatePasswordForm.getValues().password_confirm;
        const url = config.restUrl + "update_password";
        const headers = {
            'Authorization': `Bearer ${this.token}`
        };
        CognovisRestSystemService.postUpdatePassword({
            password:password,
            passwordConfirm:passwordConfirm
        })
        .then(res => {
            window.location.href = "/#!/login";
        })
        .catch(err => {
            webix.alert({
                title: err.message,
                text: err.body.message,
                type: "alert-error",
                width: 500,
                css:"cog-remove-tasks-modal"
            });
        });
    }

    showErrorAlert(title:string, error:string):void {
        webix.alert({
            type:"error",
            title:title,
            text:error,
            position:"center"
        })
        .then(() => {
            this.focusOnField("password");
        });
    }

    getForm():webix.ui.form {
        const rootView = this.getRoot() as webix.ui.view;
        const forms = rootView.queryView({ view: "form" }, "all");
        let formToReturn;
        forms.map(form => {
            if(form.isVisible()) {
                formToReturn = form;
            }
        });
        return formToReturn
    }

    focusOnField(field:string) {
        const form = this.getForm();
        if(form.elements[field]) {
            form.elements[field].focus();
        }
    }

}
