import { i18nHelper } from "../../modules/i18n-helper/i18n-helper";
import { IJetApp} from "webix-jet";
import { IUser, WebixPortalFreelancerService } from "../../openapi";
import { WebixHelpers } from "../../modules/webix-helpers/webix-helpers";
import { CognovisPleaseWaitWindow } from "../../services/cognovis-please-wait-window";
import SkillDetailsModal from "./skill-details-modal"
import CognovisBasicPortlet from "../../../sources/modules/cognovis-basic-portlet";
import { container } from "tsyringe";

export default class SkillsPortlet extends CognovisBasicPortlet {

    idPrefix = "slt";
    title:string;
    defaultTitle = i18nHelper.getTranslation("User_skills");
    userId:number;
    webixHelpers:WebixHelpers;
    userData:IUser;
    cognovisPleaseWaitWindow: CognovisPleaseWaitWindow;
    skillDetailsModal: SkillDetailsModal;
    portletId:number;

    constructor(app:IJetApp, userId:number, portletId:number, title?:string) {
        super(app, {});
        this.userId = userId;
        this.portletId = portletId;
        if(title) {
            this.title = title;
        } else {
            this.title = this.defaultTitle;
        }
    }

    init():void {
        this.cognovisPleaseWaitWindow = container.resolve(CognovisPleaseWaitWindow);   
        if(!this.skillDetailsModal) {
            this.skillDetailsModal = this.ui(SkillDetailsModal) as SkillDetailsModal;
        } 
        super.init();
        this.setHeaderTitle(this.title);
        this.components = [
            {
                view:"layout",
                minHeight:400,
                cols:[
                    this.getSkillsTable()
                ]
            }
        ];
        this.insertComponents();
        this.refreshSkillsData();
    }

    getHeader():webix.ui.layoutConfig {
        const header = {
            view:"layout",
            type:"clean",
            css: "cog-box-header",
            height:44,
            cols: [
                { 
                    view: "template", 
                    type: "header",
                    css: "cog-box-header",  
                    template: `${this.title}`, 
                    borderless: true
                },
                { 
                    view:"button", 
                    type:"icon", 
                    css:"cog-icon-dark",
                    localId:`${this.idPrefix}AddNewSkillButton`,
                    gravity:9,
                    width:20, 
                    icon:"fal fa-plus", 
                    align:"right",
                    click:() => {
                        this.skillDetailsModal.openModal(this.userId, () => {
                            this.refreshSkillsData();
                        },{});
                    }
                }
            ]
        };
        return header;
    }

    getSkillsTable():webix.ui.datatableConfig {
        return {
            view:"datatable",
            borderless:true,
            id:`${this.idPrefix}FreelancerSkillsTable`,
            columns:[
                {
                    name:"skill_type",
                    header: [i18nHelper.getTranslation("Skill_type"), { content: "multiSelectFilter" }],
                    map:"#skill_type.name#",
                    fillspace:true,
                    sort: "text"
                },
                {
                    name:"skill",
                    header: [i18nHelper.getTranslation("Skill"), { content: "multiSelectFilter" }],
                    map:"#skill.name#",
                    fillspace:true,
                    sort: "text"
                },
                {
                    name:"confirmed_experience",
                    header: [i18nHelper.getTranslation("Experience"), { content: "multiSelectFilter" }],
                    map:"#confirmed_experience?.name#",
                    sort: "text"
                },
                {  
                    name:"actions-edit",
                    width:32,
                    tooltip:false,
                    template:() => {
                        return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-edit'></span>`;
                    }
                },
                {  
                    name:"actions-delete",
                    width:32,
                    tooltip:false,
                    template:() => {
                        return `<span style='color:grey' class='webix_icon cursor-pointer fas fa-trash'></span>`;
                    }
                }
            ],
            onClick:{
                "fa-edit":(event,row) => {
                    const table = webix.$$(`${this.idPrefix}FreelancerSkillsTable`) as webix.ui.datatable;
                    const skillData = table.getItem(row);
                    this.skillDetailsModal.openModal(this.userId, () => {
                        this.refreshSkillsData();
                    },skillData);
                },
                "fa-trash":(event, row) => {
                    const table = webix.$$(`${this.idPrefix}FreelancerSkillsTable`) as webix.ui.datatable;
                    const item = table.getItem(row);
                    const confirmWarning = i18nHelper.getTranslation("lt_sure_remove_skill") + `<br/>`;
                    webix.confirm({
                        title: i18nHelper.getTranslation("any_are_you_sure_message"),
                        type: "confirm-warning",
                        text: confirmWarning,
                        width: 480
                    })
                    .then(() => {
                        this.cognovisPleaseWaitWindow.show({ message: i18nHelper.getTranslation("Please_wait")});
                        WebixPortalFreelancerService.deleteFreelancerSkill({
                            userId:this.userId,
                            skillId:item.skill.id
                        })
                        .then(() => {
                            this.refreshSkillsData();
                        })
                        .catch(err => {
                            webix.alert({
                                title: err.message,
                                text: err.body.message,
                                type: "alert-error",
                                width: 500,
                                css:"cog-remove-tasks-modal"
                            });
                        })
                        .finally(() => {
                            this.cognovisPleaseWaitWindow.hide();
                        });
                    });
                }
            }
        };
    }

    openPriceUploader(): void {
        const csvUploader = webix.$$(`${this.idPrefix}-CsvUploader`) as webix.ui.uploader
        csvUploader.fileDialog();
    }

    refreshSkillsData():void {
        const table = webix.$$(`${this.idPrefix}FreelancerSkillsTable`) as webix.ui.datatable;
        if(table) {
            table.clearAll();
            webix.extend(this.$$(`${this.idPrefix}FreelancerSkillsTable`), webix.ProgressBar);
            (table as webix.ui.datatable & {showProgress:(info:string) => void}).showProgress(i18nHelper.getTranslation("Please_wait"));
            WebixPortalFreelancerService.getFreelancerSkill({
                userId:this.userId,
            })
            .then(skills => {
                table.define("data", skills);
                table.refresh();
                table.hideOverlay();
                if(skills.length === 0) {
                    table.showOverlay(i18nHelper.getTranslation("No data"));
                }
            })
            .catch(err => {
                table.define("data", []);
                table.refresh();
                table.hideOverlay();
                this.showError(err);
            });
        }
    }
}